import React from "react";
import Select, { components } from "react-select";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import codesWithFlags from "./codesWithFlags.json";

import { isRtl } from "utils/helpers";

const PhonePrefixWrapper = styled("div")`
  ${(props) => (props.rtl ? "margin-left: 2px;" : "margin-right: 2px;")};
  margin-bottom: 10px;

  .react-select {
    &-container {
      font-family: Open Sans;
      font-weight: 600;
      font-size: 14px;
    }

    &__value-container {
      max-height: 44px;
      min-width: 52px;
      padding: 2px;
      order: 1;
    }

    &__control {
      max-height: 44px;
      width: 100%;

      background-color: rgba(255, 255, 255, 0.2) !important;
      border-radius: 0px !important;
      border-left-style: solid !important;
      border-left-width: 1px !important;
      border: none !important;
      cursor: pointer !important;

      &--is-focused {
        box-shadow: unset !important;
      }

      &--menu-is-open {
        box-shadow: unset !important;
      }
    }

    &__single-value {
      display: flex;
      margin-left: 0px !important;
      margin-right: 8px !important;
      color: #ffffff !important;
    }

    &__indicator {
      color: #ffffff !important;

      svg {
        cursor: pointer !important;
      }

      &:hover {
        color: rgba(255, 255, 255, 0.3) !important;
      }

      &-separator {
        display: none;
      }
    }

    &__menu {
      min-width: 302px !important;
      margin-top: 0;
      border-radius: 0px !important ;
      background: linear-gradient(180deg, #213a2e -11.25%, #1b493f 141.25%) !important;
    }

    &__option {
      min-width: 302px !important;
      cursor: pointer !important;
      color: #ffffff !important;
      &--is-selected {
        background-color: unset !important;
      }
      &--is-focused {
        background-color: #1b493f !important;
      }
    }
  }
`;

export const options = [
  { value: "+971", label: "971 +" },
  { value: "+981", label: "981 +" },
  { value: "+991", label: "991 +" },
];

const styles = {
  width: "20px",
  height: "20px",
  marginRight: "10px",
};

const { Option, SingleValue } = components;

const singleOption = (props) => {
  return (
    <Option
      {...props}
      style={{ display: "flex",  justifyContent: "center", alignItems: "center", position: 'absolute' }} className={'phone-prefix'}>
        <span style={{position: 'relative', top: '3px'}}>
            {props.data.flag ? <img src={props.data.flag} alt='flag' style={styles}  /> : null}
        </span>

      &nbsp;
        <span>
            {props.data.name} {props.data.code}
        </span>

    </Option>
  );
};

const singleValue = (props) => (
        <SingleValue
            {...props}
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {props.data.flag ? <img src={props.data.flag} alt='flag' style={styles} /> : null}
            &nbsp;
            {props.data.code}
        </SingleValue>
);

export default function PhonePrefix(props) {
  const { i18n } = useTranslation();

  const {
    meta,
    hideErrors,
    input: { value, name, onChange },
  } = props;

  return (
    <>
      <PhonePrefixWrapper rtl={isRtl(i18n.language)}>
        <Select
          className='react-select-container'
          classNamePrefix='react-select'
          name={name}
          value={value}
          onChange={onChange}
          // options={sortBy(phones)}

          options={codesWithFlags}
          components={{
            Option: singleOption,
            SingleValue: singleValue,
          }}
          isRtl={false}
          isSearchable={false}
          // inputProps={{readOnly:true}}
        />
      </PhonePrefixWrapper>
      {meta.touched && meta.error && !hideErrors && typeof meta.error !== "object" && (
        <span className='form-error'>{meta.error}</span>
      )}
    </>
  );
}

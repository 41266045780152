import { fork, put, takeEvery } from "redux-saga/effects";

import { GET_OFFICE_INFO_REQUEST } from "redux/constants";
import { getOfficeInfoSuccess, getOfficeInfoError } from "redux/actions";

import { getInstance } from "services/api";
import { OFFICE_INFO_API } from "constants/api";

function* watchOfficeInfo() {
  yield takeEvery(GET_OFFICE_INFO_REQUEST, handleGetOfficeInfo);
}

function* handleGetOfficeInfo({ payload }) {
  try {
    const {
      data: { data },
    } = yield getInstance().get(OFFICE_INFO_API(payload));
    yield put(getOfficeInfoSuccess(data));
  } catch (error) {
    yield put(getOfficeInfoError(error));
  }
}

export default function* officeInfoSagas() {
  yield fork(watchOfficeInfo);
}

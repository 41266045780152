import { fork, put, takeEvery } from 'redux-saga/effects';

import { GET_TESTIMONIALS_REQUEST } from 'redux/constants';
import { getTestimonialsError, getTestimonialsSuccess } from 'redux/actions';

import { getInstance } from 'services/api';
import { TESTIMONIALS_API } from 'constants/api';

function* watchTestimonials() {
  yield takeEvery(GET_TESTIMONIALS_REQUEST, handleGetTestimonials);
}

function* handleGetTestimonials() {
  try {
    const { data } = yield getInstance().get(TESTIMONIALS_API);
    yield put(getTestimonialsSuccess(data));
  } catch (error) {
    yield put(getTestimonialsError(error));
  }
}

export default function* testimonialsSagas() {
  yield fork(watchTestimonials);
}

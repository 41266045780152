import { fork, put, takeEvery } from "redux-saga/effects";

import { GET_ABOUT_INFO_REQUEST } from "redux/constants";
import { getAboutInfoError, getAboutInfoSuccess } from "redux/actions";

import { getInstance } from "services/api";
import { ABOUT_INFO_API } from "constants/api";

function* watchAboutInfo() {
  yield takeEvery(GET_ABOUT_INFO_REQUEST, handleGetAboutInfo);
}

function* handleGetAboutInfo() {
  try {
    const { data } = yield getInstance().get(ABOUT_INFO_API);
    yield put(getAboutInfoSuccess(data));
  } catch (e) {
    yield put(getAboutInfoError(e));
  }
}

export default function* AboutInfoSagas() {
  yield fork(watchAboutInfo);
}

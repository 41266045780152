import {GET_SERVICES_LIST_INFO_ERROR, GET_SERVICES_LIST_INFO_SUCCESS} from "redux/constants";


const initialState = {
    data: [],
    errors: null,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_SERVICES_LIST_INFO_SUCCESS:
            const {data} = payload;
            return { ...state, data: data, errors: null };
        case GET_SERVICES_LIST_INFO_ERROR:
            return { ...state, errors: payload, data: [] };
        default:
            return state;
    }
}
import {
    GET_SERVICE_PAGE_INFO_ERROR,
    GET_SERVICE_PAGE_INFO_SUCCESS,
} from "redux/constants";

const initialState = {
    data: {
        title: '',
        subtitle: '',
        button_text: '',
        button_url: null,
        header_image: '',
        preview_text: null,
        seo1_text: null,
        seo1_image: null,
        pros_title: null,
        pros_subtitle: null,
        pros_columns: null,
        benefit_title: null,
        benefit_subtitle: null,
        benefit_columns: null,
        seo2_text: null,
        seo2_image: null,
        form_title: null,
        show_preview: true,
        show_seo1:  true,
        show_pros: true,
        show_benefit:true,
        show_seo2:true,
        show_form: true,
    },
    errors: null,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_SERVICE_PAGE_INFO_SUCCESS:
            return { ...state, data: payload, errors: null };
        case GET_SERVICE_PAGE_INFO_ERROR:
            return { ...state, errors: payload, data: [] };

        default:
            return state;
    }
}
import React from "react";
import { Link } from "react-router-dom";

import "./index.scss";
import {bookingTour} from "utils/analytics/enents";

export default function Button({
  to,
  size = "m",
  style,
  styleType = "",
  children,
  onClick,
  href,
}) {

  if (to) {
    return (
      <Link
        onClick={onClick}
        to={to}
        style={style}
        className={`button size-${size} ${styleType} reset-link`}
      >
        {children}
      </Link>
    );
  } else if (href) {
    return (
      <a
        style={style}
        className={`button size-${size} ${styleType} reset-link`}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => bookingTour()}
      >
        {children}
      </a>
    );
  } else {
    return (
      <button
        style={style}
        className={`button size-${size} ${styleType}`}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }
}

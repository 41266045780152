import { fork, put, takeEvery } from "redux-saga/effects";

import { GET_BROKERS_INFO_REQUEST } from "redux/constants";
import { getBrokersInfoError, getBrokersInfoSuccess } from "redux/actions";

import { getInstance } from "services/api";
import { BROKERS_INFO_API } from "constants/api";

function* watchBrokersInfo() {
  yield takeEvery(GET_BROKERS_INFO_REQUEST, handleGetBrokersInfo);
}

function* handleGetBrokersInfo() {
  try {
    const { data } = yield getInstance().get(BROKERS_INFO_API);
    yield put(getBrokersInfoSuccess(data));
  } catch (e) {
    yield put(getBrokersInfoError(e));
  }
}

export default function* brokersInfoSagas() {
  yield fork(watchBrokersInfo);
}

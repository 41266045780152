import { GET_CONTACT_PAGE_SUCCESS, GET_CONTACT_PAGE_ERROR } from 'redux/constants';

const initialState = {
    data: {
        data: {
            title: null,
            subtitle: null,
            description: null,
            address: null,
            phone: null,
            email: null,
            image: null,
        }
    },
    error: null,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_CONTACT_PAGE_SUCCESS:
            return {
                ...state,
                data: payload,
            };

        case GET_CONTACT_PAGE_ERROR:
            return {
                ...state,
                error: payload,
            };

        default: return state;
    }
};
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { footerLinks } from './data.js';
import './index.scss';

import Placeify from '../Placeify';
import LanguageSelect from '../LanguageSelect';

import { ReactComponent as LogoSvg } from 'assets/svg/the-place-big.svg';
import { ReactComponent as AppStoreSvg } from 'assets/svg/app-store.svg';
import { ReactComponent as GooglePlaySvg } from 'assets/svg/google-play.svg';
import { ReactComponent as FbSvg } from 'assets/svg/socials/fb.svg';
import { ReactComponent as LinkedinSvg } from 'assets/svg/socials/linkedin.svg';
import { ReactComponent as InstaSvg } from 'assets/svg/socials/instagram.svg';
import PaymentsIcons from 'assets/icons/visa-mastercard-icons.webp';

import {
  HOME_ROUTE,
  APP_STORE_ROUTE,
  GOOGLE_PLAY_ROUTE,
  LINKED_IN_ROUTE,
  NO_ROUTE,
} from 'constants/routes';
import { BASE_URL } from 'constants/api';

import { isRtl } from 'utils/helpers';
import { getFooterLinks } from 'redux/actions';
import { downloadIOSApp, downloadAndroidApp } from 'utils/analytics/enents';
import {
  pathEqualLink,
  linkIncludesPath,
  pathIncludesSlug,
} from 'utils/functions';

const LanguageWrapper = styled('div')`
  .lang-select-wrapper::after {
    ${(props) => (props.rtl ? 'left: 15px;' : 'right: 15px;')};
  }
`;

export default function Footer() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [lastLang, setLastLang] = useState(null);
  const { legal, places } = useSelector((state) => state.footer.data);
  const servicesList = useSelector((state) => state.servicesList.data);
  const location = useLocation();

  /*let footerServicesLinks = [];
  for (let key in servicesList) {
    footerServicesLinks.push({link: key, label: servicesList[key]})
  }*/

  useEffect(() => {
    if (lastLang !== i18n.language) {
      // change default BASE_URL based on current language
      axios.defaults.baseURL = `${BASE_URL}${i18n.language}`;

      setLastLang(i18n.language);
      dispatch(getFooterLinks());
    }
  }, [dispatch, i18n.language, lastLang]);

  return (
    <footer className='section-footer'>
      <div className='top'>
        <div className='on-mobile-wrapper'>
          <Link
            to={
              pathEqualLink(location.pathname, HOME_ROUTE)
                ? NO_ROUTE
                : HOME_ROUTE
            }
            className={
              pathEqualLink(location.pathname, HOME_ROUTE)
                ? 'logo disabled-link'
                : 'logo'
            }
          >
            <LogoSvg />
          </Link>
          <LanguageWrapper rtl={isRtl(i18n.language)}>
            <div className='lang-select-wrapper only-mobile'>
              <LanguageSelect footer />
            </div>
          </LanguageWrapper>
        </div>
        <div className='links'>
          <div className='link-column'>
            {/*<p className='like-header-h2'>*/}
            {/*  <Placeify mode='uppercase'>**theplace**</Placeify>*/}
            {/*  <p className={'title-location'}>Locations</p>*/}
            {/*</p>*/}
            <p>{t('FOOTER.locations')}</p>
            {places.map(({ title, link }, index) => (
              <a
                key={index}
                href={
                  linkIncludesPath(location.pathname, link) ? NO_ROUTE : link
                }
                className={
                  linkIncludesPath(location.pathname, link)
                    ? 'disabled-link'
                    : ''
                }
              >
                {title}
              </a>
            ))}
          </div>
          <div className='link-column'>
            <p>
              <Placeify mode='uppercase'>
                {t(`FOOTER.SERVICES.servicesPlaceholder`)}
              </Placeify>
            </p>
            {servicesList.map(({ title, slug }, index) => (
              <a
                key={index}
                href={
                  pathEqualLink(location.pathname, `/${slug}`)
                    ? NO_ROUTE
                    : `/${slug}`
                }
                className={
                  pathEqualLink(location.pathname, `/${slug}`)
                    ? 'disabled-link'
                    : ''
                }
              >
                {title}
              </a>
            ))}
          </div>
          {footerLinks.map(({ heading, headingLink, list }, index) => (
            <div className='link-column' key={index}>
              <p>
                <Placeify mode='uppercase'>{t(`FOOTER.${heading}`)}</Placeify>
              </p>
              {list.map(({ text, link }, index) => (
                <Link
                  key={index}
                  to={pathEqualLink(location.pathname, link) ? NO_ROUTE : link}
                  className={
                    pathEqualLink(location.pathname, link)
                      ? 'disabled-link'
                      : ''
                  }
                >
                  {t(`FOOTER.${text}`)}
                </Link>
              ))}
            </div>
          ))}
          <div className='link-column'>
            <p>
              <Placeify mode='uppercase'>{t('FOOTER.legal')}</Placeify>
            </p>
            {legal.map(({ title, slug }, index) => (
              <Link
                to={
                  pathIncludesSlug(location.pathname, slug)
                    ? NO_ROUTE
                    : '../../' + slug
                }
                className={
                  pathIncludesSlug(location.pathname, slug)
                    ? 'disabled-link'
                    : ''
                }
                key={index}
              >
                {title}
              </Link>
            ))}
          </div>
        </div>
        <div className='apps only-desktop'>
          <a onClick={downloadIOSApp} href={APP_STORE_ROUTE}>
            <AppStoreSvg />
          </a>
          <a onClick={downloadAndroidApp} href={GOOGLE_PLAY_ROUTE}>
            <GooglePlaySvg />
          </a>
        </div>
      </div>
      <div className='bottom'>
        <LanguageWrapper rtl={isRtl(i18n.language)}>
          <div className='lang-select-wrapper only-desktop'>
            <LanguageSelect footer />
          </div>
        </LanguageWrapper>

        <div className='apps only-mobile'>
          <a onClick={downloadIOSApp} href={APP_STORE_ROUTE}>
            <AppStoreSvg />
          </a>
          <a onClick={downloadAndroidApp} href={GOOGLE_PLAY_ROUTE}>
            <GooglePlaySvg />
          </a>
        </div>

        <p className={isRtl(i18n.language) && 'rtl'}>
          <span>{` ${
            '2020' +
            (new Date().getFullYear() === 2020
              ? ''
              : '-' + new Date().getFullYear())
          }. `}</span>{' '}
          {t('FOOTER.all rights reserved')}
        </p>
        <div className='socials'>
          <img
            loading='lazy'
            src={PaymentsIcons}
            className={'payments_icons'}
            alt='payments'
          />
          <a
            href='https://www.facebook.com/The-Place-Group-116282650210653/?modal=admin_todo_tour'
            target={'_blank'}
            rel='noopener noreferrer'
          >
            <FbSvg />
          </a>
          <a href={LINKED_IN_ROUTE} target={'_blank'} rel='noopener noreferrer'>
            <LinkedinSvg />
          </a>
          <a
            href='https://www.instagram.com/theplace.group/'
            target={'_blank'}
            rel='noopener noreferrer'
            style={{ marginTop: '4px' }}
          >
            <InstaSvg />
          </a>
        </div>
        <img
          src={PaymentsIcons}
          className='payments_icons_mobile'
          alt='payments-mobile'
        />
      </div>
    </footer>
  );
}

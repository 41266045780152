import {
  GET_FOOTER_LINKS_REQUEST,
  GET_FOOTER_LINKS_ERROR,
  GET_FOOTER_LINKS_SUCCESS,
} from "redux/constants";

export function getFooterLinks() {
  return {
    type: GET_FOOTER_LINKS_REQUEST,
  };
}

export function getFooterLinksSuccess(data) {
  return {
    type: GET_FOOTER_LINKS_SUCCESS,
    payload: data,
  };
}

export function getFooterLinksError(err) {
  return {
    type: GET_FOOTER_LINKS_ERROR,
    payload: err,
  };
}

import {
  GET_BROKERS_INFO_ERROR,
  GET_BROKERS_INFO_REQUEST,
  GET_BROKERS_INFO_SUCCESS,
} from "redux/constants";

export function getBrokersInfo() {
  return {
    type: GET_BROKERS_INFO_REQUEST,
  };
}

export function getBrokersInfoSuccess(data) {
  return {
    type: GET_BROKERS_INFO_SUCCESS,
    payload: data,
  };
}

export function getBrokersInfoError(err) {
  return {
    type: GET_BROKERS_INFO_ERROR,
    payload: err,
  };
}

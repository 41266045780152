import React, { useEffect, useState } from "react";
import { AppBar } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";

import "./index.scss";

import Root from "components/common/Header/root";
import MobileMenu from "components/common/Header/MobileMenu";
import {useApiData} from "utils/hooks";
import {getServicesListInfo} from "redux/actions";
import ReactResizeDetector from "react-resize-detector";

export default function Header({history}) {
  useApiData(getServicesListInfo());
  const [classes, setClasses] = useState({
    rootClass: "menuRoot onTop",
    logoClass: "menuLogo onTop",
  });

  const handleScroll = () => {
    if (window.scrollY === 0) {
      onTop();
    } else {
      onScroll();
    }
  };

  const onTop = () =>
    setClasses({
      rootClass: "menuRoot onTop",
      logoClass: "menuLogo onTop",
    });

  const onScroll = () =>
    setClasses({
      rootClass: "menuRoot onScroll",
      logoClass: "menuLogo onScroll",
    });

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
      <ReactResizeDetector handleWidth>
        {({ width }) => {
          if (width < 1920) {
            return (
              <AppBar position="fixed" className={classes.rootClass}>
                <Toolbar>
                  <Root  className={classes.logoClass} />
                  <MobileMenu />
                </Toolbar>
              </AppBar>
            )
          } else {
            return (
              <AppBar position="absolute" className={classes.rootClass}>
                <Toolbar>
                  <Root  className={classes.logoClass} />
                  <MobileMenu />
                </Toolbar>
              </AppBar>
            )
          }
        }}
      </ReactResizeDetector>

  );
}

import {
  GET_OFFICE_INFO_ERROR,
  GET_OFFICE_INFO_REQUEST,
  GET_OFFICE_INFO_SUCCESS,
} from "redux/constants";

export function getOfficeInfo(slug) {
  return {
    type: GET_OFFICE_INFO_REQUEST,
    payload: slug,
  };
}

export function getOfficeInfoSuccess(data) {
  return {
    type: GET_OFFICE_INFO_SUCCESS,
    payload: data,
  };
}

export function getOfficeInfoError(err) {
  return {
    type: GET_OFFICE_INFO_ERROR,
    payload: err,
  };
}

import {
    GET_SERVICE_PAGE_INFO_REQUEST,
    GET_SERVICE_PAGE_INFO_SUCCESS,
    GET_SERVICE_PAGE_INFO_ERROR,
} from "redux/constants"

export function GetServicePageInfo(slug){
    return  {
        type: GET_SERVICE_PAGE_INFO_REQUEST,
        payload: slug,
    }
}

export function getServicePageInfoSuccess(data) {
    return {
        type: GET_SERVICE_PAGE_INFO_SUCCESS,
        payload: data,
    };
}

export function getServicePageInfoError(err) {
    return {
        type: GET_SERVICE_PAGE_INFO_ERROR,
        payload: err,
    };
}
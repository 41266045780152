import { fork, put, takeEvery } from "redux-saga/effects";

import { GET_FOOTER_LINKS_REQUEST } from "redux/constants";
import { getFooterLinksError, getFooterLinksSuccess } from "redux/actions";

import { getInstance } from "services/api";
import { FOOTER_LEGAL_API, FOOTER_PLACES_API } from "constants/api";

function* watchFooterLinks() {
  yield takeEvery(GET_FOOTER_LINKS_REQUEST, handleGetFooterLinks);
}

function* handleGetFooterLinks() {
  const promise1 = getInstance().get(FOOTER_LEGAL_API);
  const promise2 = getInstance().get(FOOTER_PLACES_API);
  try {
    const { 0: legalResponse, 1: placesResponce } = yield Promise.all([
      promise1,
      promise2,
    ]);
    const data = { legal: legalResponse.data, places: placesResponce.data };
    yield put(getFooterLinksSuccess(data));
  } catch (e) {
    yield put(getFooterLinksError(e));
  }
}

export default function* FooterLinksSagas() {
  yield fork(watchFooterLinks);
}

import {
  POST_CONTACT_US_ERROR,
  RESET_CONTACT_US,
  POST_CONTACT_US_SUCCESS
} from "redux/constants";

const initialState = {
  success: null,
  errors: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case POST_CONTACT_US_SUCCESS:
      return { ...state, success: true, errors: null };
    case POST_CONTACT_US_ERROR:
      return { ...state, errors: payload, success: null };
    case RESET_CONTACT_US:
      return { ...state, success: null };

    default:
      return state;
  }
}

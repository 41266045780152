import React from "react";
import Routes from "components/common/Header/root/content/routes";
import Actions from "components/common/Header/root/content/actions";
import { Wrapper } from "./style";
import { isRtl } from "utils/helpers";
import { useTranslation } from "react-i18next";

export default function Content({history}) {
  const { i18n } = useTranslation();

  return (
    <Wrapper rtl={isRtl(i18n.language)}>
      <Routes />
      <Actions />
    </Wrapper>
  );
}

import i18n from 'i18next';

// export const ERRORS = {
//   required: "Field is required",
//   email: "Enter valid email address",
//   number: "Enter valid number",
//   biggerThanZero: "Value must be bigger than zero",
//   lengthFive: "Min length is 5 numbers"
// };

export const isRequired = v =>
    !v || (v && typeof v === "string" && !v.replace(/ /g, "").length)
      ? i18n.t('VALIDATION.required')
      : typeof v === "object"
          ? Object.keys(v).length === 0
              ? i18n.t('VALIDATION.required')
              : undefined
          : undefined;

export const isEmail = v =>
    !v
        ? i18n.t('VALIDATION.email')
        : !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(v)
        ? i18n.t('VALIDATION.email')
        : undefined;

export const isNumber = v =>
    typeof v === "number"
        ? undefined
        : !v
        ? i18n.t('VALIDATION.number')
        : v instanceof Array
            ? i18n.t('VALIDATION.number')
            : !/^\d*$/i.test(v)
                ? i18n.t('VALIDATION.number')
                : undefined;

export const biggerThanZero = v =>
    !v ? i18n.t('VALIDATION.biggerThanZero') : v <= 0 ? i18n.t('VALIDATION.biggerThanZero') : undefined;

export const removeLetters = (v) =>
    v ? v.replace(/\D+/g, "") : v;

export const maxLength = (max) => (v) =>
    v.length > max ? v.slice(0, max) : v;

export const maxNumberLength = (max) => (v) =>
    v.length > max ? removeLetters(v.slice(0, max)) : removeLetters(v);

export const isRangeFrom1To25 = (v) =>
    !v
        ? `Enter valid range from ${1} to ${25}`
        : v < 1 || v > 25
        ? `Enter valid range from ${1} to ${25}`
        : undefined;

export const isLength5 = v =>
    !v ? i18n.t('VALIDATION.lengthFive') : v.length < 5 ? i18n.t('VALIDATION.lengthFive') : undefined;

export const coordinatesRegExp = /^(-?[\d]*\.[\d]*), +(-?[\d]*\.[\d]*)&?$/;
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import "./index.scss";

import LanguageSelect from "components/common/LanguageSelect";
import Login from "../root/content/actions/login";
import { isRtl } from "utils/helpers";

import {
  HOME_ROUTE,
  // ABOUT_ROUTE,
  LOCATIONS_ROUTE,
  CONTACT_US_ROUTE,
  LINKED_IN_ROUTE,
  APP_STORE_ROUTE,
  GOOGLE_PLAY_ROUTE,
} from "constants/routes";

import { ReactComponent as AppStoreSvg } from "assets/svg/app-store.svg";
import { ReactComponent as GooglePlaySvg } from "assets/svg/google-play.svg";
import { ReactComponent as FbSvg } from "assets/svg/socials/fb.svg";
import { ReactComponent as LinkedinSvg } from "assets/svg/socials/linkedin.svg";
import { ReactComponent as InstaSvg } from "assets/svg/socials/instagram.svg";
import ServicesDropdown from "components/common/Header/root/content/ServicesDropdown";
import PartnershipDropdown from "components/common/Header/root/content/PartnershipDropdown";

const MobileButtonWrapper = styled('div')`
  .mobile-menu-button  {
     ${(props) => (props.rtl ? "left: 16px;" : "right: 16px;")};
  }
`;

const LanguageWrapper = styled('div')`
  .react-select-container::before  {
     ${(props) => (props.rtl ? "right: 5px;" : "left: 5px;")};
  }  
  .react-select__value-container  {
     ${(props) => (props.rtl ? "padding-right: 40px;" : "padding-left: 40px;")};
  }
`;

export default function MobileMenu() {
  const [opened, setOpened] = useState(false);
  const { t, i18n } = useTranslation();

  const toggleMenu = () => setOpened(!opened);

  return (
    <>
      <div className={`mobile-menu ${opened ? "opened" : ""}`}>
        <div className="top">
          <menu className="menu">
            <NavLink exact={true} to={HOME_ROUTE} onClick={toggleMenu}>{t("HEADER.home")}</NavLink>
            {/*<NavLink to={ABOUT_ROUTE} onClick={toggleMenu}>{t("HEADER.about")}</NavLink>*/}
            <ServicesDropdown />
            <PartnershipDropdown />
            <NavLink to={LOCATIONS_ROUTE} onClick={toggleMenu}>{t("HEADER.locations")}</NavLink>
            <NavLink to={CONTACT_US_ROUTE} onClick={toggleMenu}>{t("HEADER.contactUs")}</NavLink>
          </menu>
          <div className="actions">
            <Login />
            <LanguageWrapper rtl={isRtl(i18n.language)}>
              <LanguageSelect />
            </LanguageWrapper>
          </div>
        </div>
        <div className="bottom">
          <div className="apps">
            <a href={APP_STORE_ROUTE}>
              <AppStoreSvg />
            </a>
            <a href={GOOGLE_PLAY_ROUTE}>
              <GooglePlaySvg />
            </a>
          </div>
          <div className="socials">
            <a href="/">
              <FbSvg />
            </a>
            <a href={LINKED_IN_ROUTE}>
              <LinkedinSvg />
            </a>
            <a href="/">
              <InstaSvg />
            </a>
          </div>
        </div>
      </div>
      <MobileButtonWrapper rtl={isRtl(i18n.language)}>
        <button
          className={`mobile-menu-button ${opened ? "opened" : ""}`}
          onClick={toggleMenu}>
          <div/>
          <div/>
          <div/>
        </button>
      </MobileButtonWrapper>
    </>
  );
}

import { fork, put, takeEvery } from "redux-saga/effects";

import { GET_SERVICES_LIST_INFO_REQUEST } from "redux/constants";

import { getInstance } from "services/api";
import {getServicesListInfoError, getServicesListInfoSuccess} from "redux/actions";
import {SERVICES_LIST_INFO_API} from "constants/api";

function* watchServicesListInfo() {
    yield takeEvery(GET_SERVICES_LIST_INFO_REQUEST, handleGetServicesInfo);
}

function* handleGetServicesInfo() {
    try {
        const { data } = yield getInstance().get(SERVICES_LIST_INFO_API);
        yield put(getServicesListInfoSuccess(data));
    } catch (error) {
        yield put(getServicesListInfoError(error));
    }
}

export default function* servicesListInfoSagas() {
    yield fork(watchServicesListInfo);
}

import {
  GET_TESTIMONIALS_ERROR,
  GET_TESTIMONIALS_REQUEST,
  GET_TESTIMONIALS_SUCCESS,
} from 'redux/constants';

export function getTestimonials(slug) {
  return {
    type: GET_TESTIMONIALS_REQUEST,
    payload: slug,
  };
}

export function getTestimonialsSuccess({ data }) {
  return {
    type: GET_TESTIMONIALS_SUCCESS,
    payload: data,
  };
}

export function getTestimonialsError(err) {
  return {
    type: GET_TESTIMONIALS_ERROR,
    payload: err,
  };
}

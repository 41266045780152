import React from 'react';
import flagIcons from 'assets/svg/language-spite.svg';

export const options = [
  {
    value: 'en',
    label: 'English',
    icon: (
      <svg width='18' height='18' role='img'>
        <use xlinkHref={`${flagIcons}#flag-icons-en`}></use>
      </svg>
    ),
  },
  {
    value: 'ru',
    label: 'Русский',
    icon: (
      <svg width='18' height='18' role='img'>
        <use xlinkHref={`${flagIcons}#flag-icons-ru`}></use>
      </svg>
    ),
  },
  {
    value: 'ar',
    label: 'العربية',
    icon: (
      <svg width='18' height='18' role='img'>
        <use xlinkHref={`${flagIcons}#flag-icons-ar`}></use>
      </svg>
    ),
  },
];

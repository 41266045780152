import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { LANGUAGE_ATTRIBUTE } from 'constants/settings';
import {
  ABOUT_ROUTE,
  BROKERS_ROUTE,
  CONTACT_US_ROUTE,
  COOKIE_POLICY_ROUTE,
  CORPORATE_CLIENTS_ROUTE,
  COWORKING_SPACE_ROUTE,
  DMCC_ROUTE,
  EVENT_SPACES_ROUTE,
  HELP_ROUTE,
  HOME_ROUTE,
  LOCATIONS_ROUTE,
  MEETING_ROOMS_ROUTE,
  PRIVACY_POLICY_ROUTE,
  PRIVATE_OFFICE_ROUTE,
  REFERRAL_ROUTE,
  TERMS_ROUTE,
  THE_ONE_TOWER_ROUTE,
  VIRTUAL_OFFICE_ROUTE,
  LANDLORDS_ROUTE,
} from 'constants/routes';
import { useTranslation } from 'react-i18next';

import openSansRegular from 'assets/fonts/opensans/open-sans-regular-latin.woff2';
import openSansSemiBold from 'assets/fonts/opensans/open-sans-semi-bold-latin.woff2';
import dinProCondBold from 'assets/fonts/din-pro-cond/din-pro-condensed-bold-latin.woff2';
import dinProCondBlack from 'assets/fonts/din-pro-cond/din-pro-condensed-black-latin.woff2';

import logoAnimated from 'assets/img/logo.gif';

/*
import {meta} from "constants/meta";
*/

export default function MetaTags() {
  const [pathname, setPathname] = useState('/');
  const [locationMeta, setLocationMeta] = useState({});
  const location = useLocation();

  const { t, i18n } = useTranslation();

  const currentLng = i18n.language || window.localStorage.i18next || '';

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  /* useEffect(() => {
        console.log(locationMeta)
    }, [locationMeta]);*/

  useEffect(() => {
    switch (location.pathname) {
      case HOME_ROUTE:
        setLocationMeta('meta.Homepage');
        break;
      case ABOUT_ROUTE:
        setLocationMeta('meta.About');
        break;
      case LOCATIONS_ROUTE:
        setLocationMeta('meta.Locations');
        break;
      case THE_ONE_TOWER_ROUTE:
        setLocationMeta('meta.TheOneTower');
        break;
      case DMCC_ROUTE:
        setLocationMeta('meta.DMCC');
        break;
      case PRIVATE_OFFICE_ROUTE:
        setLocationMeta('meta.OfficeSpace');
        break;
      case COWORKING_SPACE_ROUTE:
        setLocationMeta('meta.CoworkingSpace');
        break;
      case MEETING_ROOMS_ROUTE:
        setLocationMeta('meta.MeetingAndConferenceRoom');
        break;
      case EVENT_SPACES_ROUTE:
        setLocationMeta('meta.EventSpaces');
        break;
      case VIRTUAL_OFFICE_ROUTE:
        setLocationMeta('meta.VirtualOffice');
        break;
      case CONTACT_US_ROUTE:
        setLocationMeta('meta.ContactUs');
        break;
      case PRIVACY_POLICY_ROUTE:
        setLocationMeta('meta.PrivacyPolicy');
        break;
      case HELP_ROUTE:
        setLocationMeta('meta.Help');
        break;
      case CORPORATE_CLIENTS_ROUTE:
        setLocationMeta('meta.ForCorporateClients');
        break;
      case TERMS_ROUTE:
        setLocationMeta('meta.TermsConditions');
        break;
      case REFERRAL_ROUTE:
        setLocationMeta('meta.ReferralProgram');
        break;
      case COOKIE_POLICY_ROUTE:
        setLocationMeta('meta.CookiePolicy');
        break;
      case BROKERS_ROUTE:
        setLocationMeta('meta.ForBrokers');
        break;
      case LANDLORDS_ROUTE:
        setLocationMeta('meta.Landlords');
        break;
      default:
        setLocationMeta('meta.default');
    }
  }, [pathname]);
  return (
    <Helmet>
      <title>{t(`${locationMeta}.title`)}</title>
      <link
        rel='alternate'
        href={`https://theplace.group${pathname}`}
        hreflang={LANGUAGE_ATTRIBUTE[currentLng]}
      />
      <link rel='canonical' href={`https://theplace.group${pathname}`} />
      <link
        rel='preload'
        href={openSansRegular}
        as='font'
        type='font/woff2'
        crossorigin
      />
      <link
        rel='preload'
        href={openSansSemiBold}
        as='font'
        type='font/woff2'
        crossorigin
      />
      <link
        rel='preload'
        href={dinProCondBold}
        as='font'
        type='font/woff2'
        crossorigin
      />
      <link
        rel='preload'
        href={dinProCondBlack}
        as='font'
        type='font/woff2'
        crossorigin
      />
      <link rel='preload' href={logoAnimated} as='image' crossorigin />
      /* Primary Meta Tags */
      <meta name='title' content={t(`${locationMeta}.title`)} />
      <meta name='description' content={t(`${locationMeta}.description`)} />
      <meta
        name='keywords'
        content='the place, dubai, coworking, office, broker, landlord, private office, virtual office, meeting room'
      />
      /* Open Graph / Facebook */
      <meta property='og:type' content='website' />
      <meta property='og:image' content='https://i.ibb.co/cC9qDdZ/OGP.jpg' />
      <meta property='og:url' content={`https://theplace.group${pathname}`} />
      <meta property='og:title' content={t(`${locationMeta}.title`)} />
      <meta
        property='og:description'
        content={t(`${locationMeta}.description`)}
      />
      <meta name='robots' content='index, follow' />
      /* Twitter */
      <meta property='twitter:card' content='summary_large_image' />
      <meta
        property='twitter:image'
        content='https://i.ibb.co/cC9qDdZ/OGP.jpg'
      />
      <meta
        property='twitter:url'
        content={`https://theplace.group${pathname}`}
      />
      <meta property='twitter:title' content={t(`${locationMeta}.title`)} />
      <meta
        property='twitter:description'
        content={t(`${locationMeta}.description`)}
      />
      /*Add Social Network profiles for "Google knowledge panel" */
      {/*<script type="application/ld+json"*/}
      {/*src={ `https://theplace.group/${location.pathname}`}*/}
      {/*>{`*/}
      {/*        {*/}
      {/*            "@context": "http://schema.org",*/}
      {/*            "name": "The Place",*/}
      {/*            type: "itemReviewed",*/}
      {/*            "sameAs": [*/}
      {/*         "https://www.facebook.com/theplace.group/",*/}
      {/*         "https://www.linkedin.com/company/the-place-bc/",*/}
      {/*         "https://www.instagram.com/theplace.group/"*/}
      {/*     ]*/}
      {/*        }*/}
      {/*    `}</script>*/}
    </Helmet>
  );
}

import { fork, put, takeEvery } from 'redux-saga/effects';

import { GET_CONTACT_PAGE_REQUEST } from 'redux/constants';
import {getContactPageSuccess, getContactPageError } from 'redux/actions';

import { getInstance } from 'services/api';
import { CONTACT_PAGE_INFO_API } from 'constants/api';

function* watchContactPageRequest() {
    yield takeEvery(GET_CONTACT_PAGE_REQUEST, handleContactPage);
}

function* handleContactPage() {
    try {
        const { data } = yield getInstance().get(CONTACT_PAGE_INFO_API);
        yield put(getContactPageSuccess(data));
    } catch (error) {
        yield put(getContactPageError(error));
    }
}

export default function* contactPageSagas() {
    yield fork(watchContactPageRequest);
}
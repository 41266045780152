import { GET_LOCATIONS_INFO_ERROR, GET_LOCATIONS_INFO_SUCCESS, GET_LOCATIONS_INFO_SEARCH_SUCCESS } from 'redux/constants';

const initialState = {
    data: [],
    locations: [],
    workspaces: [],
    errors: null,
};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case GET_LOCATIONS_INFO_SUCCESS:
            return {
                ...state,
                data: payload,
                locations: [],
                workspaces: [],
                errors: null,
            };


        case GET_LOCATIONS_INFO_SEARCH_SUCCESS:
            return {
                ...state,
                data: [],
                locations: payload.locations,
                workspaces: payload.workspaces,
            };

        case GET_LOCATIONS_INFO_ERROR:
            return {...state, errors: payload, data: []};
        default:
            return state;
    }
}

import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 1023px) {
    padding: 0 ${({ rtl }) => (!rtl ? "44" : "0")}px 0
      ${({ rtl }) => (rtl ? "44" : "0")}px;
  }
`;

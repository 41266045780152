export const CONTACT_US_FORM_NAME = "contactUsForm";
export const MODAL_FORM_NAME = "modalForm";
export const WORKSPACES_FORM_NAME = "workspacesForm";
export const REFERRAL_FORM_NAME = "referralForm";

export const initialValues = {
  prefix: {
    flag: "https://flagicons.lipis.dev/flags/4x3/ae.svg",
    name: "United Arab Emirates",
    code: "+971",
  },
  prefix2: {
    flag: "https://flagicons.lipis.dev/flags/4x3/ae.svg",
    name: "United Arab Emirates",
    code: "+971",
  },
};

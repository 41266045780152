import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import {
  DEFAULT_LANGUAGE,
  RTL_LANGUAGES,
  LANGUAGE_ATTRIBUTE,
} from 'constants/settings';
import { en, ru, ar } from 'constants/languages';

const resources = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
  ar: {
    translation: ar,
  },
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .on('initialized', () => {
    if (RTL_LANGUAGES.includes(i18next.language)) {
      document.documentElement.dir = 'rtl';
    }
  })
  .on('languageChanged', (lng) => {
    document.documentElement.setAttribute('lang', LANGUAGE_ATTRIBUTE[lng]);
  })
  .init({
    lng: DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE,
    resources,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
      useSuspense: false,
    },
    detection: {
      order: ['localStorage', 'cookie'],
      caches: ['localStorage', 'cookie'],
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18next',
      excludeCacheFor: ['cimode'],
      cookieMinutes: 10,
      cookieDomain: 'theplace.group',
    },
  });

export default i18next;

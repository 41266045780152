import React from 'react';


const Textarea = ({ meta, input, ...props }) => {
  return (
    <>
      <textarea {...input} {...props} />

      {meta && meta.touched && meta.error && typeof meta.error !== 'object' && (
        <span className="form-error">{meta.error}</span>
      )}
    </>
  );
};

export default Textarea;

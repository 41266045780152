import React from "react";
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

import "./index.scss";

import {ABOUT_ROUTE, CONTACT_US_ROUTE, LOCATIONS_ROUTE, NO_ROUTE} from "constants/routes";
import {pathEqualLink} from "utils/functions";
import ServicesDropdown from "components/common/Header/root/content/ServicesDropdown";
import PartnershipDropdown from "components/common/Header/root/content/PartnershipDropdown";

export default function Routes({history}) {
    const {t} = useTranslation();
    const location = useLocation();

    return (
        <ul className="routes">
            {/*<li className="route">
                <Link to={pathEqualLink(location.pathname, ABOUT_ROUTE) ? NO_ROUTE : ABOUT_ROUTE}
                      className={pathEqualLink(location.pathname, ABOUT_ROUTE) ? 'disabled-link' : ''}
                >{t("HEADER.about")}</Link>
            </li>
            <li>
                <div className="line"/>
            </li>*/}
            <li className="route">
                <Link to={pathEqualLink(location.pathname, LOCATIONS_ROUTE) ? NO_ROUTE : LOCATIONS_ROUTE}
                      className={pathEqualLink(location.pathname, LOCATIONS_ROUTE) ? 'disabled-link' : ''}
                >{t("HEADER.locations")}</Link>
            </li>
            <li>
                <div className="line"/>
            </li>
            <li className="route">
                <PartnershipDropdown />
            </li>
            <li>
                <div className="line"/>
            </li>
            <li className="route">
                <ServicesDropdown/>
            </li>
            <li>
                <div className="line"/>
            </li>
            <li className="route mobile-visible">
                <Link to={pathEqualLink(location.pathname, CONTACT_US_ROUTE) ? NO_ROUTE : CONTACT_US_ROUTE}
                      className={pathEqualLink(location.pathname, CONTACT_US_ROUTE) ? 'disabled-link' : ''}
                >{t("HEADER.contactUs")}</Link>
            </li>
        </ul>
    );
}

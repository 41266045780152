import {
  GET_TESTIMONIALS_ERROR,
  GET_TESTIMONIALS_SUCCESS,
} from 'redux/constants';

const initialState = {
  data: [
    {
      comment: '',
      logo: '',
    },
  ],
  errors: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TESTIMONIALS_SUCCESS:
      return { ...state, data: payload, errors: null };
    case GET_TESTIMONIALS_ERROR:
      return { ...state, errors: payload, data: [] };

    default:
      return state;
  }
}

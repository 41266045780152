export function createMarkup(string) {
    return {__html: string};
}

export function deleteTag(string, tag) {
    return string?.replace(tag, "");
}

export const pathEqualLink = (location, link) => {
    return location === link;
}

export const pathIncludesSlug = (location, link) => {
    return location.includes(link);
}

export const linkIncludesPath = (location, link) => {
     return location !== '/' && link.includes(location);
}

export const uniqueID = () => {
    return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase()
}


const defaultDescription = "Офисы в аренду в Дубае готовы для команды любого размера - на любой срок. Лидеры в области дизайна создали офисные помещения с прекрасным видом, климат-контролем и безопасным доступом.";

export const ru = {
  HEADER: {
    home: "Главная",
    about: "О нас",
    locations: "Локации",
    contactUs: "Контакты",
    logIn: "Войти",
  },
  FOOTER: {
    company: "компания",
    partnership: "партнерство",
    legal: "условия оферты:",
    about: "о нас",
    locations: "Локации",
    SERVICES: {
      servicesPlaceholder: "Сервисы",
    },
    "help (FAQs)": "помощь (FAQ)",
    "contact us": "контакты",
    "for brokers": "для брокеров",
    "for landlords": "для арендодателей",
    "for corporate clients": "для корпоративных клиентов",
    "for referral": "реферальная программа",
    "all rights reserved": "Все права защищены ©",
  },
  FORM: {
    "contact us": "Связаться с нами",
    "full name": "ФИО",
    "brokerage name": "Брокерское имя",
    "company name": "Имя компании",
    "your contact details": "Ваши контактные данные",
    "your referral`s details": "Данные реферала",
    email: "e-mail",
    "business email": "Бизнес E-mail",
    "phone number": "телефон",
    "team size": "Размер команды",
    "city interest": "Город",
    city: "Город",
    sqft: "sqft",
    homeQuestion: "Хотите увидеть?",
    homeAnswer: "Позвольте нам записать вас",
    comments: "Комментарии",
    "budget & additional requirements": "Бюджет и дополнительные требования",
    "still have": "Все еще есть",
    question: "Нравится The Place?",
    answer:
        "Мы с радостью вам поможем реализовать такой же! Услуга Built-to-Suit позволяет вам арендовать большие помещения или целые этажи с полной экосистемой The Place, в вашем корпоративном стилем.",
    address: "Адрес",
    telephone: "Телефон",
    'terms_first': 'Я согласен с',
    'terms_second': ' условиями пользования сайтом ',
    'terms_third': 'и обработку моих персональных данных',
    // 'terms': 'Я соглаюсь с условиями сайта  и обработкой моих персональных данных.',
  },
  BUTTONS: {
    "book a tour": "забронировать тур",
    "start tour": "начать тур",
    send: "отправить",
    "book now": "забронировать",
    "become the place broker": "стать брокером",
    search: "поиск",
    "contact us": "связаться",
    "go back home": "На главную",
    more: "Узнать больше",
    "learn more": "Узнать больше",
  },
  NOT_FOUND: {
    "page not found": "Ошибка: 404 Страница не найдена",
    "Sorry, the page you're looking for cannot be accessed":
        "К сожалению, страница, которую вы ищете, недоступна. Пожалуйста, проверьте адрес.",
    "go back home": "На главную",
  },
  SEARCH_SECTION: {
    title: "Мир это прекрасное место",
    subtitle:
        "Мы создаем качественные, стильные пространства по всему миру. Снимаете ли вы новый офис в Шейх Заед" +
        " Роуд или строите штаб-квартиру в Даунтауне - у нас есть **theplace** !",
    placeholderCountry: "Страна",
    placeholderCity: "Город",
    placeholderTypes: "Тип",
    placeholderCapacity: "Вместимость",
    placeholderInput: "Офис, адрес...",
  },
  BREADCRUMBS: {
    home: 'Главная',
    brokers: "Для брокеров",
    corporateClients: "Для корпоративных клиентов"
  },
  meta: {
    Homepage: {
      title: "Качественные стильные офисы в аренду в Дубае для команды любого размера - The Place",
      description: "Офисы в аренду в Дубае готовы для команды любого размера - на любой срок. Лидеры в области дизайна создали офисные помещения с прекрасным видом, климат-контролем и безопасным доступом.",
    },
    OfficeSpace: {
      title: "Аренда офисов в Дубае - Офисные помещения в аренду с обслуживанием - The Place",
      description: "Аренда офисных помещений в Дубае для команды любого размера - на любой срок. Индивидуальные решения под ключ для любых бизнес-потребностей. Наши офисы обеспечат продуктивность!",
    },
    CoworkingSpace: {
      title: "Аренда коворкинга в Дубае | ОАЭ - The Place",
      description: "Коворкинг в Дубае - на любой срок. День, месяц или дольше? Выберите одно из наших совместных рабочих мест. ",
    },
    MeetingAndConferenceRoom: {
      title: "Аренда конференц-залов в Дубае, ОАЭ | Бронируйте Конференц-зал для мероприятий - The Place",
      description: "Забронируйте конференц-зал в Дубае - Объединенные Арабские Эмираты. Современные конференц-залы вместимостью до 12 человек, большие телевизоры, HDMI и напитки.",
    },
    BusinessCentre: {
      title: "Бизнес-центр в Дубае - Аренда офисов в Бизнес-центре - The Place",
      description: "Предлагаем комплексные решения для открытия бизнеса в Дубае. Ведущий бизнес-центр в Дубае открыт круглосуточно и без выходных. Записывайтесь на бесплатный тур сегодня!",
    },
    VirtualOffice: {
      title: "Виртуальный офис аренда в Дубае - Виртуальный адрес для бизнеса - The Place",
      description: "Виртуальный офис предлагает гибкую и экономичную альтернативу постоянному офису в Дубае. Зарегистрируйте виртуальный офис. Воспользуйтесь нашим престижным адресом. ",
    },
    EventSpaces: {
      title: "Аренда площадок для мероприятий в Дубае - ThePlace",
      description: "Площадки для мероприятий в Арабских Эмиратах. Откройте для себя лучшие места для проведения мероприятий в Дубае. Идеальное место для различных мероприятий или торжеств",
    },
    TheOneTower: {
      title: "The One Tower - бизнес-центр в Дубае | The Place",
      description: "The One Tower, расположенный в самом центре Дубая, одно из самых выгодных мест для бизнеса. Наслаждайтесь исключительным офисным пространством с The Place.",
    },
    DMCC: {
      title: "DMCC - бизнес-центр в Дубае | The Place",
      description: "Dubai Multi Commodities Centre Authority (DMCC). Одно из самых выгодных мест для бизнеса. Наслаждайтесь исключительным офисным пространством с The Place.",
    },
    About: {
      title: "О нас - The Place",
      description: "Информация о компании The Place",
    },
    ContactUs: {
      title: "Контакты. Свяжитесь с нами - The Place",
      description: "Свяжитесь с нами сейчас + 971 (0) 4879 07 00",
    },
    Locations: {
      title: "Локации - The Place",
      description: defaultDescription,
    },
    PrivacyPolicy: {
      title: "Политика конфиденциальности - The Place",
      description: defaultDescription,
    },
    Help: {
      title: "Помощь - The place",
      description: defaultDescription,
    },
    ForCorporateClients: {
      title: "Корпоративным клиентам - The Place",
      description: defaultDescription,
    },
    TermsConditions: {
      title: "Пользовательское соглашение - The Place",
      description: defaultDescription,
    },
    ReferralProgram: {
      title: "Реферальная программа - The Place",
      description: defaultDescription,
    },
    ForBrokers: {
      title: "Брокерам - The Place",
      description: defaultDescription,
    },
    CookiePolicy: {
      title: "Политика использования файлов cookie - The Place",
      description: defaultDescription,
    },
    default: {
      title: "The Place",
      description: defaultDescription,
    }
  },
  static:{
    readMore: 'Показать больше',
    readLess: 'Показать меньше',
    tourTitle: '3D тур',
    workspaceTitle: 'Больше информации',
    workspaceServices: 'Услуги',
    googleLink: 'Ссылка на карту Google',
    bookTitle: 'Заинтересованы в ',
    bookSubTitle: 'Забронируйте сейчас',
    bookFormDesc: 'Заполните информацию',
    workplacesTitle: 'Доступные места',
    officeServiceTitle: 'Дополнительные услуги'
  },
  LAW: {
    cookies: 'Этот сайт использует файлы cookie, чтобы обеспечить вам удобство пользования',
    cookiesLink: 'Узнать больше',
    cookieBtn: 'Понятно'
  },
  PRESS_SECTION: {
    title: 'ПРЕССА О НАС'
  },
  VALIDATION: {
    required: "Обязательное поле",
    email: "Некорректная почта",
    number: "Некорректный номер",
    biggerThanZero: "Значение должно быть больше чем ноль",
    lengthFive: "Минимум 5 символов"
  }
};

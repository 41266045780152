export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const HELP_INFO_API = `help`;
export const CONTACT_US_API = `form`;
export const ABOUT_INFO_API = `about`;
export const FOOTER_LEGAL_API = `legal`;
export const HOMEPAGE_INFO_API = `home`;
export const BROKERS_INFO_API = `brokers`;
export const LANDLORDS_INFO_API = `landlords`;
export const FOOTER_PLACES_API = `footer-places`;
export const LEGAL_INFO_API = (slug) => `legal/${slug}`;
export const OFFICE_INFO_API = (slug) => `locations/${slug}`;
export const SERVICES_LIST_INFO_API = `services`;
export const SERVICE_PAGE_INFO_API = (slug) => `services/${slug}`;
export const LOCATIONS_SEARCH_API = `locations/search?`;
export const CORPORATE_CLIENTS_INFO_API = `corporate-clients`;
export const CONTACT_PAGE_INFO_API = `contact`;
export const FILTER_DATA_API = `locations/get-filter-data`;
export const REFERRALS_API = `referrals`;
export const PRESS_API = `press`;
export const TESTIMONIALS_API = `testimonials`;

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

import Content from "components/common/Header/root/content";
import Logo from "components/common/Header/root/logo";
import {Wrapper} from "components/common/Header/root/style";

import {HOME_ROUTE, NO_ROUTE} from "constants/routes";

export default class Root extends PureComponent {
    render() {
        const isHomePage = () => {
            return window.location.href.split('/').pop() === '';
        }

        return (
            <Wrapper>
                <Link to={isHomePage() ? NO_ROUTE : HOME_ROUTE}
                      className={isHomePage() ? 'disabled-link' : ''}>
                    <Logo className={this.props.className}/>
                </Link>
                <Content />
            </Wrapper>
        );
    }
}

Root.propTypes = {className: PropTypes.string};

import { fork, put, takeEvery } from "redux-saga/effects";

import { GET_SERVICE_PAGE_INFO_REQUEST } from "redux/constants";
import { getServicePageInfoSuccess, getServicePageInfoError } from "redux/actions";

import { getInstance } from "services/api";
import { SERVICE_PAGE_INFO_API } from "constants/api";

function* watchServicePageInfo() {
    yield takeEvery(GET_SERVICE_PAGE_INFO_REQUEST, handleGetServicePageInfo);
}

function* handleGetServicePageInfo({ payload }) {
    try {
        const {
            data: { data },
        } = yield getInstance().get(SERVICE_PAGE_INFO_API(payload));
        yield put(getServicePageInfoSuccess(data));
    } catch (error) {
        yield put(getServicePageInfoError(error));
    }
}

export default function* servicePageInfoSagas() {
    yield fork(watchServicePageInfo);
}
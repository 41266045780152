import {
  GET_HELP_INFO_ERROR,
  GET_HELP_INFO_REQUEST,
  GET_HELP_INFO_SUCCESS,
  GET_HELP_INFO_SEARCH_RESULTS_ERROR,
  GET_HELP_INFO_SEARCH_RESULTS_REQUEST,
  GET_HELP_INFO_SEARCH_RESULTS_SUCCESS,
} from "redux/constants";

export function getHelpInfo() {
  return {
    type: GET_HELP_INFO_REQUEST,
  };
}

export function getHelpInfoSuccess(data) {
  return {
    type: GET_HELP_INFO_SUCCESS,
    payload: data,
  };
}

export function getHelpInfoError(err) {
  return {
    type: GET_HELP_INFO_ERROR,
    payload: err,
  };
}

export function getHelpSearchResultsInfo(slug) {
  return {
    type: GET_HELP_INFO_SEARCH_RESULTS_REQUEST,
    payload: slug,
  };
}

export function getHelpSearchResultsSuccess(data) {
  return {
    type: GET_HELP_INFO_SEARCH_RESULTS_SUCCESS,
    payload: data,
  };
}

export function getHelpSearchResultsError(err) {
  return {
    type: GET_HELP_INFO_SEARCH_RESULTS_ERROR,
    payload: err,
  };
}

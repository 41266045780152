import { fork, put, takeEvery } from 'redux-saga/effects';

import { GET_PRESS_REQUEST } from 'redux/constants';
import { getPressError, getPressSuccess } from 'redux/actions';

import { getInstance } from 'services/api';
import { PRESS_API } from 'constants/api';

function* watchPress() {
  yield takeEvery(GET_PRESS_REQUEST, handleGetPress);
}

function* handleGetPress() {
  try {
    const { data } = yield getInstance().get(PRESS_API);
    yield put(getPressSuccess(data));
  } catch (error) {
    yield put(getPressError(error));
  }
}

export default function* pressSagas() {
  yield fork(watchPress);
}

import { GET_ABOUT_INFO_ERROR, GET_ABOUT_INFO_SUCCESS } from "redux/constants";

const initialState = {
  data: {
    blocks_titles: {},
    header: {},
    story: {},
    about: {},
    approaches: [],
    testimonials: [],
    for_landlords: {},
    facts: [],
  },
  errors: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ABOUT_INFO_SUCCESS:
      return { ...state, data: payload, errors: null };
    case GET_ABOUT_INFO_ERROR:
      return { ...state, errors: payload, data: [] };

    default:
      return state;
  }
}

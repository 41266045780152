import { GET_LOCATIONS_INFO_ERROR, GET_LOCATIONS_INFO_REQUEST, GET_LOCATIONS_INFO_SUCCESS,
    GET_LOCATIONS_INFO_SEARCH_SUCCESS } from 'redux/constants';

export function getLocationsInfo(search) {
    return {
        type: GET_LOCATIONS_INFO_REQUEST,
        payload: search,
    };
}

export function getLocationsInfoSuccess(data) {
    return {
        type: GET_LOCATIONS_INFO_SUCCESS,
        payload: data,
    };
}

export function getLocationsInfoSearchSuccess(search) {
    return {
        type: GET_LOCATIONS_INFO_SEARCH_SUCCESS,
        payload: search,
    };
}

export function getLocationsInfoError(err) {
    return {
        type: GET_LOCATIONS_INFO_ERROR,
        payload: err,
    };
}




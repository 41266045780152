import React from "react";
import {render} from "react-dom";
import {Provider} from "react-redux";
import {store} from "redux/store";
import {I18nextProvider} from "react-i18next";

import i18n from "services/localization";
import Root from "containers/root";
import TagManager from 'react-gtm-module';
import "./index.scss";


// if (process.env.NODE_ENV === 'production') {
    const tagManagerArgs = {
        gtmId: 'GTM-TBJ6ZDQ',
        dataLayerName: 'PageDataLayer'
    }
    TagManager.initialize(tagManagerArgs)
// }

const element = document.getElementById("root");

render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <Root/>
        </I18nextProvider>
    </Provider>,
    element
);



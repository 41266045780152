import { FILTER_DATA_SUCCESS, FILTER_DATA_ERROR } from 'redux/constants';

const initialState = {
    data: {
        countries: [],
        cities: [],
        types: [],
        /*capacity: [],*/
    }
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case FILTER_DATA_SUCCESS:
            return {
                ...state,
                data: payload,
            };
        case FILTER_DATA_ERROR:
            return {
                ...state,
                error: payload,
            };
        default: return state;
    }
};
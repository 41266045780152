import { fork, put, takeEvery } from 'redux-saga/effects'

import { FILTER_DATA_REQUEST } from 'redux/constants';
import { getFilterDataSuccess, getFilterDataError } from 'redux/actions';

import { getInstance } from 'services/api';
import { FILTER_DATA_API } from 'constants/api';

function* watchFilterDataRequest() {
    yield takeEvery(FILTER_DATA_REQUEST, handleFilterData);
}

function* handleFilterData() {
    try {
        const { data } = yield getInstance().get(FILTER_DATA_API);
        yield put(getFilterDataSuccess(data));
    } catch (e) {
        yield put(getFilterDataError(e));
    }
}

export default function* filterDataSagas() {
    yield fork(watchFilterDataRequest);
}
import {BROKERS_ROUTE, CORPORATE_CLIENTS_ROUTE, LANDLORDS_ROUTE, REFERRAL_ROUTE} from "constants/routes";

export const data =  [
    {
        text: "for brokers",
        link: BROKERS_ROUTE,
    },
    {
        text: "for landlords",
        link: LANDLORDS_ROUTE,
    },
    {
        text: "for corporate clients",
        link: CORPORATE_CLIENTS_ROUTE,
    },
    {
        text: "for referral",
        link: REFERRAL_ROUTE,
    },
]
import React from "react";
import Select from "react-select";
import './index.scss';

export default function HeaderDropdown({title, options}) {
    //TODO: need better solution
    const handleChange = selectedOption => {
        window.location.pathname = selectedOption.value;
    };
    const uniqueId = "select_" + Math.random().toFixed(5).slice(2);

    return (
        <>
            <Select
                id={uniqueId}
                className={`header-select`}
                classNamePrefix="react-select"
                placeholder={title}
                onChange={handleChange}
                isRtl={false}
                options={options}
                isSearchable={false}
                menuPlacement="auto"
                onMenuClose={() => {
                    if (window.innerWidth > 1199) {
                        const menuEl = document.querySelector(`.react-select__menu`);
                        const containerEl = menuEl?.parentElement;
                        const clonedMenuEl = menuEl?.cloneNode(true);
                        if (!clonedMenuEl) return;
                        clonedMenuEl.classList.add("menu--close");
                        clonedMenuEl.addEventListener("animationend", () => {
                            containerEl.removeChild(clonedMenuEl);
                        });
                        containerEl.appendChild(clonedMenuEl);
                    }
                }}
            />
        </>
    )
}
import { fork, put, takeEvery } from "redux-saga/effects";

import {
  GET_HELP_INFO_REQUEST,
  GET_HELP_INFO_SEARCH_RESULTS_REQUEST,
} from "redux/constants";

import {
  getHelpInfoError,
  getHelpInfoSuccess,
  getHelpSearchResultsSuccess,
  getHelpSearchResultsError,
} from "redux/actions";

import { getInstance } from "services/api";
import { HELP_INFO_API } from "constants/api";

function* watchHelpInfo() {
  yield takeEvery(GET_HELP_INFO_REQUEST, handleGetHelpInfo);
  yield takeEvery(
    GET_HELP_INFO_SEARCH_RESULTS_REQUEST,
    handleHelpSearchResults
  );
}

function* handleGetHelpInfo() {
  try {
    const { data } = yield getInstance().get(HELP_INFO_API);
    yield put(getHelpInfoSuccess(data));
  } catch (e) {
    yield put(getHelpInfoError(e));
  }
}

function* handleHelpSearchResults({ payload }) {
  try {
    const { data } = yield getInstance().get(
      `${HELP_INFO_API}/search?query=${payload}`
    );
    yield put(getHelpSearchResultsSuccess(data));
  } catch (error) {
    yield put(getHelpSearchResultsError(error));
  }
}

export default function* HelpInfoSagas() {
  yield fork(watchHelpInfo);
}

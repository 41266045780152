import {
  GET_OFFICE_INFO_ERROR,
  GET_OFFICE_INFO_SUCCESS,
} from "redux/constants";

const initialState = {
  data: {
    title: null,
    subtitle: null,
    general_image: null,
    mobile_image: null,
    tablet_image: null,
    tour: {},
    long_description: null,
    gallery: [],
    workspaces: [],
    facts_title: null,
    facts: [],
    services: [],
    address: null,
    coordinates: null,
    map_image: null,
    city: null,
    manager_name: null,
    manager_position: null,
    manager_comment: null,
    manager_image: null,
    form_image: null,
    nexudus_site_slug: null,
    slug: null,
  },

  errors: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_OFFICE_INFO_SUCCESS:
      return { ...state, data: payload, errors: null };
    case GET_OFFICE_INFO_ERROR:
      return { ...state, errors: payload, data: [] };

    default:
      return state;
  }
}

import { fork, put, takeEvery } from "redux-saga/effects";

import { GET_CORPORATE_CLIENTS_INFO_REQUEST } from "redux/constants";
import {
  getCorporateClientsInfoError,
  getCorporateClientsInfoSuccess,
} from "redux/actions";

import { getInstance } from "services/api";
import { CORPORATE_CLIENTS_INFO_API } from "constants/api";

function* watchCorporateClientsInfo() {
  yield takeEvery(
    GET_CORPORATE_CLIENTS_INFO_REQUEST,
    handleGetCorporateClientsInfo
  );
}

function* handleGetCorporateClientsInfo() {
  try {
    const { data } = yield getInstance().get(CORPORATE_CLIENTS_INFO_API);
    yield put(getCorporateClientsInfoSuccess(data));
  } catch (e) {
    yield put(getCorporateClientsInfoError(e));
  }
}

export default function* corporateClientsInfoSagas() {
  yield fork(watchCorporateClientsInfo);
}

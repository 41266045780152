import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 70px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    height: 60px;
  }

  @media screen and (min-width: 600px) and (max-width: 1199px) {
    height: 116px;
  }
`;

import {
  GET_FOOTER_LINKS_ERROR,
  GET_FOOTER_LINKS_SUCCESS,
} from "redux/constants";

const initialState = {
  data: {
    places: [],
    legal: [],
  },
  errors: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_FOOTER_LINKS_SUCCESS:
      return { ...state, data: payload, errors: null };
    case GET_FOOTER_LINKS_ERROR:
      return { ...state, errors: payload, data: { places: [], legal: [] } };

    default:
      return state;
  }
}

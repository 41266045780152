import React from 'react';
import styled from 'styled-components';
import logoAnimated from 'assets/img/logo.gif';

const Overlay = styled('div')`
  position: fixed;
  top: 0;
  background-color: #1b493f;
  margin: 0px;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const Loader = ({ visible }) => {
  return (
    <>
      {visible ? (
        <Overlay>
          <img src={logoAnimated} width='200' height='200' alt='' />
        </Overlay>
      ) : null}
    </>
  );
};

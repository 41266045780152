import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import HeaderDropdown from "components/common/HeaderDropdown";

export default function ServicesDropdown() {
    const servicesList = useSelector(state => state.servicesList.data);
    const {t} = useTranslation();
    let services = [];
    servicesList.map((item)=> {
        services.push({value: item.slug, label: item.title})
    });
    const title = t("FOOTER.SERVICES.servicesPlaceholder");

    return <HeaderDropdown options={services} title={title} />
}
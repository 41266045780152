export const HOME_ROUTE = '/';
export const NO_ROUTE = '/disabled-link';

export const HELP_ROUTE = '/help';
export const ABOUT_ROUTE = '/about';
export const TERMS_ROUTE = '/terms-conditions';
export const LOGIN_ROUTE = 'https://theonetower.spaces.nexudus.com/login';
export const OFFICE_ROUTE = (id) => (id ? `/office/${id}` : `/office/:id`);
export const SERVICE_ROUTE = (id) => (id ? `/${id}` : `/:id`);

/*export const SERVICE_ROUTE = `/services`;*/
export const BROKERS_ROUTE = '/brokers';
export const REFERRAL_ROUTE = '/referral';
export const LANDLORDS_ROUTE = '/landlords';
export const LOCATIONS_ROUTE = '/locations';
export const CONTACT_US_ROUTE = '/contact-us';
export const PRIVACY_POLICY_ROUTE = '/privacy-policy';
export const COOKIE_POLICY_ROUTE = '/cookie-policy';
export const CORPORATE_CLIENTS_ROUTE = '/corporate-clients';

//External links
export const APP_STORE_ROUTE =
  'https://apps.apple.com/us/app/the-place-bc/id1516837485';
export const GOOGLE_PLAY_ROUTE =
  'https://play.google.com/store/apps/details?id=com.nexudusspaces.theplace';
export const LINKED_IN_ROUTE = 'https://www.linkedin.com/company/the-place-bc/';
export const CALENDAR_ROUTE =
  'https://theonetower.theplace.group/en/bookings/calendar';
/*export const MEETING_ROOMS_ROUTE =
  "https://theonetower.theplace.group/signup?portal&tariff_guid=782b93cb-5229-4768-982d-beeda068e4da";*/

//Dynamic links
export const THE_ONE_TOWER_ROUTE = '/office/the-one-tower';
export const DMCC_ROUTE = '/office/dubai-dmcc';
export const PRIVATE_OFFICE_ROUTE = '/private-offices';
export const COWORKING_SPACE_ROUTE = '/coworking-space';
export const MEETING_ROOMS_ROUTE = '/meeting-rooms';
export const EVENT_SPACES_ROUTE = '/event-spaces';
export const VIRTUAL_OFFICE_ROUTE = '/virtual-office';

import { fork, put, takeEvery } from "redux-saga/effects";

import { GET_LANDLORDS_INFO_REQUEST } from "redux/constants";
import { getLandlordsInfoError, getLandlordsInfoSuccess } from "redux/actions";

import { getInstance } from "services/api";
import { LANDLORDS_INFO_API } from "constants/api";

function* watchLandlordsInfo() {
  yield takeEvery(GET_LANDLORDS_INFO_REQUEST, handleGetLandlordsInfo);
}

function* handleGetLandlordsInfo() {
  try {
    const { data } = yield getInstance().get(LANDLORDS_INFO_API);
    yield put(getLandlordsInfoSuccess(data));
  } catch (e) {
    yield put(getLandlordsInfoError(e));
  }
}

export default function* LandlordsInfoSagas() {
  yield fork(watchLandlordsInfo);
}

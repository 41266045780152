import { fork, put, takeEvery } from 'redux-saga/effects';

import { GET_LOCATIONS_INFO_REQUEST } from 'redux/constants';
import { getLocationsInfoError, getLocationsInfoSearchSuccess, getLocationsInfoSuccess } from 'redux/actions';

import { getInstance } from 'services/api';
import { LOCATIONS_SEARCH_API } from 'constants/api';

function* watchLocationsSearch() {
    yield takeEvery(GET_LOCATIONS_INFO_REQUEST, handleGetLocationsSearch);
}

function* handleGetLocationsSearch({ payload }) {
    const search = payload?.search ? `search=${payload.search}` : '';
    const type = payload?.type ? `&type=${payload.type}` : '';
    const country = payload?.country ? `&country=${payload.country}` : '';
    const city = payload?.city ? `&city=${payload.city}` : '';/*
    const minCapacity = payload?.capacity_min ? `&capacity_min=${payload.capacity_min}` : '';
    const maxCapacity = payload?.capacity_max ? `&capacity_max=${payload.capacity_max}` : '';*/

    const params = search + type + country + city/* + minCapacity + maxCapacity*/;

    try {
        const { data } = yield getInstance().get(`${LOCATIONS_SEARCH_API}${params}`);
        if (payload) {
            if (data.data) {
                yield put(getLocationsInfoSuccess(data.data));
            } else {
                yield put(getLocationsInfoSuccess([]));
                yield put(getLocationsInfoSearchSuccess(data));
            }
        } else {
            yield put(getLocationsInfoSuccess(data.data));
        }
    } catch (e) {
        yield put(getLocationsInfoError(e));
    }
}

export default function* LocationsSearchSagas() {
    yield fork(watchLocationsSearch);
}

const defaultDescription = "مكاتب للإيجار في دبي جاهزة لفريق من أي حجم - لأي فترة. أنشأ قادة التصميم مساحات مكتبية ذات مناظر رائعة وتحكم في المناخ والوصول الآمن.\n";


export const ar = {
  HEADER: {
    home: "الصفحة الرئيسية",
    about: "نبذة عنا",
    locations: "المواقع",
    contactUs: "اتصل بنا",
    logIn: "تسجيل الدخول",
  },
  FOOTER: {
    company: "شركة",
    partnership: "الشراكة",
    legal: "شؤون قانونية",
    locations: "المواقع",

    SERVICES: {
      servicesPlaceholder: "الخدمات",
      privateOffices: "مكاتب خاصة",
      coworkingStations: "محطات عمل مشترك",
      virtualOffices: "مكاتب افتراضية",
      meetingRooms: "غرف الإجتماعات",
      eventPlaces: "أماكن الحدث",
    },
    about: "نبذة عنا",
    "help (FAQs)": "المساعدة (الأسئلة الشائعة)",
    "contact us": "اتصل بنا",
    "for brokers": "للوسطاء",
    "for landlords": "لأصحاب العقارات",
    "for corporate clients": "لعملاء الشركات",
    "for referral": "للإحالة",
    "all rights reserved": "الحقوق محفوظة. حقوق النشر© لعام",
  },
  FORM: {
    "contact us": "اتصل بنا",
    "full name": "الاسم بالكامل",
    "brokerage name": "اسم الوساطة",
    "company name": "اسم الشركة",
    "your contact details": "تفاصيل الاتصال الخاصة بك",
    "your referral`s details": "تفاصيل الإحالة الخاصة بك",
    email: "البريد الإلكتروني للأعمال",
    "business email": "البريد الإلكتروني للأعمال",
    "phone number": "رقم الهاتف",
    "team size": "حجم الفريق",
    "city interest": "المدينة محل الاهتمام",
    city: "المدينة",
    sqft: "قدم مربع",
    comments: "التعليقات",
    "budget & additional requirements": "الميزانية والمتطلبات الإضافية",
    "still have": "لا زلت املك",
    // questions: "questions",
    // "get in contact! we'll be glad to help you out":
    //   "Get in contact! We’ll be glad to help you out.",
    // "find the right working solution for your clients and earn a generous commission! Become The Place broker":
    //   "Find the right working solution for your clients and earn a generous commission! Become The Place broker.",
    homeQuestion: "حريصة على أن ترى؟",
    homeAnswer: "دعنا نحجز لك ونرشدك",
    answer:
        "سنجعلك بكل سرور واحدًا خاصًا بك! تسمح ميزة \"Built-to-Suit\" لعملائنا من الشركات باستئجار مساحات كبيرة أو طوابق كاملة مع النظام البيئي الكامل لـ The Place المدمج والمبرمج ليناسب أسلوب شركتك.",
    question: "مثل المكان؟",
    address: "عنوان",
    telephone: "هاتف",
    'terms_first': ' ومعالجة بياناتي الشخصية',
    'terms_second': ' شروط الموقع ',
    'terms_third': ' أوافق على شروط ',
  },
  BUTTONS: {
    "book a tour": "احجز جولة",
    "start tour": "بدء جولة",
    send: "إرسال",
    "book now": "احجز الآن",
    "become the place broker": "أصبح وسيط المكان",
    search: "بحث",
    "contact us": "اتصل بنا",
    "go back home": "ارجع الى البيت",
    more: "أكثر",
    "learn more": "تعرَّف على المزيد",
  },
  NOT_FOUND: {
    "page not found": "خطأ 404 - لم يتم العثور على الصفحة",
    "Sorry, the page you're looking for cannot be accessed":
        "عذرا ، الصفحة التي تبحث عنها لا يمكن الوصول إليها. من فضلك ، تحقق من URL.\n",
    "go back home": "ارجع الى البيت",
  },
  SEARCH_SECTION: {
    title: "العالم مكان رائع",
    subtitle:
        "نقوم بإنشاء مساحات أنيقة عالية الجودة نبذة عنا العالم. وسواء كنت تستأجر مكتبًا جديدًا للفريق في دبي أو تبني المقر الرئيسي في هونغ كونغ، لدينا ذابليس الذي يناسبك!",
    placeholderCountry: "دولة",
    placeholderCity: "المدينة",
    placeholderTypes: "نوع المساحة",
    placeholderCapacity: "الاهلية",
    placeholderInput: "عنوان المكتب...",
  },
  BREADCRUMBS: {
    home: 'الصفحة الرئيسية',
    brokers: "للوسطاء",
    corporateClients: "لعملاء الشركات"
  },
  meta: {
    Homepage: {
      title: "مكاتب أنيقة وعالية الجودة للإيجار في دبي لفرق العمل من أي حجم\n - The Place",
      description: "مكاتب للإيجار في دبي جاهزة لفريق من أي حجم - لأي فترة. أنشأ قادة التصميم مساحات مكتبية ذات مناظر رائعة وتحكم في المناخ والوصول الآمن.\n",
    },
    OfficeSpace: {
      title: "تأجير مكاتب دبي - مساحات مكتبية مخدومة - ذا بليس\n",
      description: "استأجر مكتبًا في دبي لفريق من أي حجم - لأي فترة. حلول جاهزة ومُصممة خصيصًا لأي احتياجات عمل. مكاتبنا سوف تضمن الإنتاجية!\n",
    },
    CoworkingSpace: {
      title: "استئجار مساحة عمل مشتركة في دبي | الإمارات العربية المتحدة\n - The Place",
      description: "العمل المشترك في دبي - لأي فترة. اليوم ، الشهر أو أكثر؟ اختر من إحدى مساحات العمل المشتركة لدينا.\n ",
    },
    MeetingAndConferenceRoom: {
      title: "إيجار قاعات مؤتمرات في دبي ، الإمارات العربية المتحدة | حجز غرفة اجتماعات للأحداث\n - The Place",
      description: "احجز قاعة المؤتمرات الخاصة بك في دبي - الإمارات العربية المتحدة. قاعات اجتماعات حديثة تتسع لـ 12 شخصًا وتلفزيونات كبيرة ومنفذ HDMI ومشروبات.\n",
    },
    BusinessCentre: {
      title: "مركز الأعمال في دبي - استئجار مكاتب في مركز الأعمال\n - The Place",
      description: "نحن نقدم حلولاً شاملة لبدء عمل تجاري في دبي. يفتح مركز الأعمال الرائد في دبي على مدار الساعة طوال أيام الأسبوع. اشترك في جولة مجانية اليوم!\n",
    },
    VirtualOffice: {
      title: "مكتب افتراضي للإيجار في دبي - عنوان افتراضي للأعمال\n - The Place",
      description: "يقدم المكتب الافتراضي بديلاً مرنًا وفعالاً من حيث التكلفة لمكتب دائم في دبي. تسجيل مكتب افتراضي. استفد من عنواننا المرموق.\n ",
    },
    EventSpaces: {
      title: "تأجير مكان للمناسبات في دبي\n - ThePlace",
      description: "أماكن للمناسبات في دولة الإمارات العربية المتحدة. اكتشف أفضل أماكن الفعاليات في دبي. المكان المثالي لمختلف الأحداث أو الاحتفالات\n",
    },
    TheOneTower: {
      title: "برج ذا ون - مركز أعمال في دبي\n | The Place",
      description: "يقع The One Tower في قلب دبي ، وهو أحد أكثر الأماكن ربحية للأعمال. استمتع بمساحة مكتبية استثنائية مع The Place\n.",
    },
    DMCC: {
      title: "DMCC - مركز الأعمال في دبي\n | The Place",
      description: "سلطة مركز دبي للسلع المتعددة (DMCC). واحدة من أفضل الأماكن لممارسة الأعمال التجارية. استمتع بمساحة مكتبية استثنائية مع The Place.\n",
    },
    About: {
      title: "نبذة عنا – ذا بليس\n",
      description: "نبذة عنا – ذا بليس\n",
    },
    ContactUs: {
      title: "جهات الاتصال. اتصل بنا - The Place\n",
      description: "اتصل بنا الآن + 971 (0) 4879 07 00\n",
    },
    Locations: {
      title: "المواقع - المكان",
      description: defaultDescription,
    },
    PrivacyPolicy: {
      title: "سياسة الخصوصية - The Place\n",
      description: defaultDescription,
    },
    Help: {
      title: "مساعدة - المكان",
      description: defaultDescription,
    },
    ForCorporateClients: {
      title: "عملاء الشركات - المكان",
      description: defaultDescription,
    },
    TermsConditions: {
      title: "اتفاقية المستخدم - المكان",
      description: defaultDescription,
    },
    ReferralProgram: {
      title: "برنامج الإحالة - المكان",
      description: defaultDescription,
    },
    ForBrokers: {
      title: "سماسرة - المكان",
      description: defaultDescription,
    },
    CookiePolicy: {
      title: "سياسة ملفات تعريف الارتباط - المكان\n",
      description: defaultDescription,
    },
    default: {
      title: "The Place",
      description: defaultDescription,
    }
  },
  static: {
    readMore: 'اقرأ أكثر',
    readLess: 'عرض أقل',
    tourTitle: 'جولة ثلاثية الأبعاد',
    workspaceTitle: 'مزيد من المعلومات',
    workspaceServices: 'الخدمات',
    googleLink: 'رابط لخريطة جوجل',
    bookTitle: 'مهتم ب ',
    bookSubTitle: 'احجز مكان عملك الآن',
    bookFormDesc: 'يرجى تقديم المعلومات الخاصة بك',
    workplacesTitle: 'مساحات العمل المتاحة',
    officeServiceTitle: 'الخدمات الإضافية'
  },
  LAW: {
    cookies: 'يستخدم هذا الموقع ملفات تعريف ارتباط ليمنحك تجربة استخدام رائعة.',
    cookiesLink: 'اكتشف المزيد',
    cookieBtn: 'حسنًا'
  },
  PRESS_SECTION: {
    title: 'نحن في أعين الصحافة'
  },
  VALIDATION: {
    required: "هذا الحقل مطلوب",
    email: "أدخل عنوان بريد إلكتروني صالح",
    number: "أدخل رقمًا صالحًا",
    biggerThanZero: "يجب أن تكون القيمة أكبر من الصفر",
    lengthFive: "الحد الأدنى للطول هو 5 أرقام"
  }
};

import {
  GET_LEGAL_INFO_ERROR,
  GET_LEGAL_INFO_REQUEST,
  GET_LEGAL_INFO_SUCCESS,
} from "redux/constants";

export function getLegalInfo(slug) {
  return {
    type: GET_LEGAL_INFO_REQUEST,
    payload: slug,
  };
}

export function getLegalInfoSuccess(data) {
  return {
    type: GET_LEGAL_INFO_SUCCESS,
    payload: data,
  };
}

export function getLegalInfoError(err) {
  return {
    type: GET_LEGAL_INFO_ERROR,
    payload: err,
  };
}

import { termsLink } from 'components/common/ContactUs/form';

const defaultDescription =
  'Quality stylish offices action ready for any size team – for any term. The Place has your work environments’ every foot and inch planned out to maximize comfort and productivity. So, how do we pull this off? It’s all about the approach.';

// const termsLink = () => {
//   return (
//       <a href="/terms-conditions"> terms of the website </a>
//   )
// }

export const en = {
  HEADER: {
    home: 'Home',
    about: 'About',
    locations: 'Locations',
    contactUs: 'Contact us',
    logIn: 'Log in',
  },
  FOOTER: {
    company: 'company',
    partnership: 'partnership',
    legal: 'legal',
    about: 'about',
    locations: 'Locations',
    SERVICES: {
      servicesPlaceholder: 'Services',
    },
    'help (FAQs)': 'help (FAQs)',
    'contact us': 'contact us',
    'for brokers': 'for brokers',
    'for landlords': 'for landlords',
    'for corporate clients': 'for corporate clients',
    'for referral': 'for referral',
    'all rights reserved': 'All rights reserved ©',
  },
  FORM: {
    'contact us': 'Contact us',
    'full name': 'Full name',
    'brokerage name': 'Brokerage name',
    'company name': 'Company name',
    'your contact details': 'Your contact details',
    'your referral`s details': 'Your referral`s details',
    email: 'Email',
    'business email': 'Business E-mail',
    'phone number': 'Phone number',
    'team size': 'Team size',
    'city interest': 'City interest',
    city: 'City',
    sqft: 'sqft',
    comments: 'Comments',
    'budget & additional requirements': 'Budget & additional requirements',
    'still have': 'Still have',
    homeQuestion: 'Eager to see?',
    homeAnswer: 'Let us book you in and guide you',
    question: 'Like the place?',
    answer:
      'We’ll gladly make you one of your own! Built-to-Suit  allows our corporate clients to rent large spaces or entire floors with The Place’s full ecosystem built-in and programmed to suit your corporate style.',
    address: 'Address',
    telephone: 'Telephone',
    terms_one:
      'I agree with the <1> terms of the website </1> and the processing of my personal data',
    terms_first: 'I agree with the',
    terms_second: ' terms of the website ',
    terms_third: 'and the processing of my personal data',
    // terms: `I agree with the terms of the website and the processing of my personal data`,
  },
  BUTTONS: {
    'book a tour': 'book a tour',
    'start tour': 'start tour',
    send: 'send',
    'book now': 'book now',
    'become the place broker': 'become the place broker',
    search: 'search',
    'contact us': 'contact us',
    'go back home': 'Go back home',
    more: 'More',
    'learn more': 'learn more',
  },
  NOT_FOUND: {
    'page not found': 'Error: 404 Page Not Found',
    "Sorry, the page you're looking for cannot be accessed":
      "Sorry, the page you're looking for cannot be accessed. Please, check the URL.",
    'go back home': 'Go back home',
  },
  SEARCH_SECTION: {
    title: 'The world is a wonderful Place',
    subtitle:
      "We create high-quality, stylish spaces around the globe. Whether you're renting a new team office on" +
      ' Sheikh Zayed Road or building the headquarters in Downtown – we have **theplace** for you!',
    placeholderCountry: 'Country',
    placeholderCity: 'City',
    placeholderTypes: 'Type of Space',
    placeholderCapacity: 'Capacity',
    placeholderInput: 'Office, address...',
  },
  BREADCRUMBS: {
    home: 'Home page',
    brokers: 'For brokers',
    corporateClients: 'For corporate clients',
  },
  meta: {
    Homepage: {
      title:
        'Quality stylish offices for rent in Dubai & Co-working Spaces – The Place',
      description:
        'Fully Furnished & IT-equipped Quality Offices for rent in Dubai. We offer Premium Serviced Offices and Co-working Spaces to Suit Your Business Needs.',
    },
    OfficeSpace: {
      title: 'Private Serviced Office space for Rent in Dubai - The Place',
      description:
        'Private Serviced Office space for Rent in Dubai for any size team – for any term. Fully Furnished & IT-equipped Offices in prime locations in Dubai.',
    },
    CoworkingSpace: {
      title:
        'Coworking space for Rent | Affordable Shared Office Space - The Place',
      description:
        'Modern high-quality coworking space in Dubai at an affordable price. Fully equipped flexible office across the Dubai with Onsite support staff for your convenience.',
    },
    MeetingAndConferenceRoom: {
      title: 'Meeting Rooms in Dubai UAE | Book Conference Room - The Place',
      description:
        "Book a fully equipped meeting room in Dubai, UAE. Contemporary Conference rooms up to 12 people along large tv's, HDMI's, and beverages. All Without Any Commitment.",
    },
    BusinessCentre: {
      title: 'Business centre for rent in Dubai - The Place',
      description:
        'Offering Complete Solution for a Business Setup in Dubai. The Leading Business Center in Dubai open 24/7. Come for a free trial today!',
    },
    VirtualOffice: {
      title: 'Virtual office | Business Centre & Serviced Office - The Place',
      description:
        'The Place offers a flexible Virtual office and cost-effective office in Dubai. Benefit from our prestigious Serviced Office address with your own receptionist.',
    },
    EventSpaces: {
      title: 'Event Spaces in Dubai | Conference Room Space - The Place',
      description:
        'Renting an event space in Dubai? Our variety of spaces are ready for events or celebrations. Book a beautiful conference room or meeting room across the Dubai.',
    },
    TheOneTower: {
      title: 'The One Tower Floor 24 | Office Space for Rent - The Place',
      description:
        'Fully Furnished Office Space for Rent in The One Tower Dubai. The Place offers Flexible and Adaptable Workspace Solutions across the Dubai, UAE.',
    },
    DMCC: {
      title: 'DMCC Mazaya Business Avenue | Co-Working Spaces - The Place',
      description:
        'Find your Perfect space, The place offers co-working space in Dubai DMCC. DMCC is a perfect place to get your business growing in Dubai with 100% ownership..',
    },
    About: {
      title: 'About us - Executive Business Centre - The Place',
      description:
        'The Place Executive Business Centre in Dubai. We create The Place Solutions which cover all your requirements and inspire you to achieve. Flexible terms, ready-to-go!',
    },
    ContactUs: {
      title: 'Contact us | Coworking Space & Shared Office - The Place',
      description:
        'Contact us for the most Luxurious Serviced Offices Solutions, Coworking Space, Virtual office, or shared office space in Dubai. Find the right workspace at the Place.',
    },
    Locations: {
      title: 'Fully Furnished Office | Executive Business Centre - The Place',
      description:
        'Rent Fully Furnished Office Space in Dubai. The Place is Leading Business Centre in Dubai open 24/7. We create high-quality, stylish spaces around the globe.',
    },
    PrivacyPolicy: {
      title: 'Privacy policy - The Place',
      description: defaultDescription,
    },
    Help: {
      title: 'Help - The place',
      description: defaultDescription,
    },
    ForCorporateClients: {
      title: 'Corporate Clients - Tailored, Turnkey Solutions - The Place',
      description:
        'Looking for a new office or want to refresh your existing one? We’ll gladly help you out! we offer tailored, turnkey solutions within The Place’s infrastructure.',
    },
    TermsConditions: {
      title: 'Terms & conditions - The Place',
      description: defaultDescription,
    },
    ReferralProgram: {
      title: 'Get a commission for every invited friend - The Place',
      description:
        'Do you know someone The Place solutions could be of interest to? Get us in touch with us and get a commission for every invited friend, who becomes our client.',
    },
    ForBrokers: {
      title: 'Become the Place Broker | Grow your business with The Place',
      description:
        'Become The Place Broker and find the perfect working environment for your clients and earn a generous commission with every contract renewal or team expansion.',
    },
    Landlords: {
      title: 'Enhance your portfolio with The Place - Partnership',
      description:
        'We welcome the landlords to join us in a long-term mutually beneficial partnership and maximise your revenue, enhance your property value and create new demand.',
    },
    CookiePolicy: {
      title: 'Cookie policy - ThePlace',
      description: defaultDescription,
    },
    DubaiChamber: {
      title: 'Dubai Chamber | Private Offices & Co-Working Spaces - The Place',
      description:
        'Join one of the safest, environmentally friendly and design winning workplaces including private offices and co-working spaces at The Place Dubai Chamber.',
    },
    default: {
      title: 'The Place',
      description: defaultDescription,
    },
  },
  static: {
    readMore: 'Read more',
    readLess: 'Show less',
    tourTitle: '3D tour',
    workspaceTitle: 'More info',
    workspaceServices: 'Services',
    googleLink: 'Link to Google Map',
    bookTitle: 'Interested in ',
    bookSubTitle: 'Book your workspace now',
    bookFormDesc: 'Please submit your information',
    workplacesTitle: 'Available Workspaces',
    officeServiceTitle: 'Additional Services',
  },
  LAW: {
    cookies: 'This site uses cookies to provide you with great user experience',
    cookiesLink: 'Find out more',
    cookieBtn: 'Got it',
  },
  PRESS_SECTION: {
    title: 'PRESS ABOUT US',
  },
  VALIDATION: {
    required: 'Field is required',
    email: 'Enter valid email address',
    number: 'Enter valid number',
    biggerThanZero: 'Value must be bigger than zero',
    lengthFive: 'Min length is 5 numbers',
  },
};

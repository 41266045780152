import { FILTER_DATA_REQUEST, FILTER_DATA_SUCCESS, FILTER_DATA_ERROR } from 'redux/constants';

export function getFilterDataRequest() {
    return {
        type: FILTER_DATA_REQUEST,
    }
}

export function getFilterDataSuccess(data) {
    return {
        type: FILTER_DATA_SUCCESS,
        payload: data,
    }
}

export function getFilterDataError(error) {
    return {
        type: FILTER_DATA_ERROR,
        payload: error,
    }
}
import React from "react";
import {useTranslation} from "react-i18next";
import HeaderDropdown from "components/common/HeaderDropdown";
import {data} from './data'

export default function PartnershipDropdown() {
    const {t} = useTranslation();
    let partnerships = [];
    data.map((item)=> {
        partnerships.push({value: item.link, label: t(`FOOTER.${item.text}`)})
    })
    const title = t("FOOTER.partnership");

    return  <HeaderDropdown title={title} options={partnerships} />
}
import {
  GET_REFERRALS_INFO_ERROR,
  GET_REFERRALS_INFO_REQUEST,
  GET_REFERRALS_INFO_SUCCESS,
} from "redux/constants";

export function getReferralsInfo() {
  return {
    type: GET_REFERRALS_INFO_REQUEST,
  };
}

export function getReferralsInfoSuccess(data) {
  return {
    type: GET_REFERRALS_INFO_SUCCESS,
    payload: data,
  };
}

export function getReferralsInfoError(error) {
  return {
    type: GET_REFERRALS_INFO_ERROR,
    payload: error,
  };
}

import {
  POST_CONTACT_US_ERROR,
  POST_CONTACT_US_REQUEST,
  POST_CONTACT_US_SUCCESS,
  RESET_CONTACT_US
} from "redux/constants/contactUs";

import {formRequestSuccess} from "utils/analytics/enents";

export function postContactUs(values) {
  return {
    type: POST_CONTACT_US_REQUEST,
    payload: values
  };
}

export function postContactUsSuccess(data, type) {
  formRequestSuccess(type);
  
  return {
    type: POST_CONTACT_US_SUCCESS,
    payload: data
  };
}

export function postContactUsError(err) {
  return {
    type: POST_CONTACT_US_ERROR,
    payload: err
  };
}

export function resetContactUs() {
  return {
    type: RESET_CONTACT_US
  };
}




export const GET_HELP_INFO_REQUEST = "@get/HELP_INFO_REQUEST";
export const GET_HELP_INFO_SUCCESS = "@get/HELP_INFO_SUCCESS";
export const GET_HELP_INFO_ERROR = "@get/HELP_INFO_ERROR";

export const GET_HELP_INFO_SEARCH_RESULTS_REQUEST =
  "@get/HELP_INFO_SEARCH_RESULTS_REQUEST";
export const GET_HELP_INFO_SEARCH_RESULTS_SUCCESS =
  "@get/HELP_INFO_SEARCH_RESULTS_SUCCESS";
export const GET_HELP_INFO_SEARCH_RESULTS_ERROR =
  "@get/HELP_INFO_SEARCH_RESULTS_ERROR";

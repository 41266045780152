import {
  HELP_ROUTE,
  ABOUT_ROUTE,
  BROKERS_ROUTE,
  LANDLORDS_ROUTE,
  CONTACT_US_ROUTE,
  CORPORATE_CLIENTS_ROUTE,
  REFERRAL_ROUTE,
} from "constants/routes";

export const footerLinks = [
  {
    heading: "company",
    list: [
      {
        text: "about",
        link: ABOUT_ROUTE,
      },
      {
        text: "help (FAQs)",
        link: HELP_ROUTE,
      },
      {
        text: "contact us",
        link: CONTACT_US_ROUTE,
      },
    ],
  },
  {
    heading: "partnership",
    list: [
      {
        text: "for brokers",
        link: BROKERS_ROUTE,
      },
      {
        text: "for landlords",
        link: LANDLORDS_ROUTE,
      },
      {
        text: "for corporate clients",
        link: CORPORATE_CLIENTS_ROUTE,
      },
      {
        text: "for referral",
        link: REFERRAL_ROUTE,
      },
    ],
  },
];

import {
  GET_HOMEPAGE_INFO_ERROR,
  GET_HOMEPAGE_INFO_REQUEST,
  GET_HOMEPAGE_INFO_SUCCESS
} from "redux/constants";

export function getHomepageInfo() {
  return {
    type: GET_HOMEPAGE_INFO_REQUEST
  };
}

export function getHomepageInfoSuccess(data) {
  return {
    type: GET_HOMEPAGE_INFO_SUCCESS,
    payload: data
  };
}

export function getHomepageInfoError(err) {
  return {
    type: GET_HOMEPAGE_INFO_ERROR,
    payload: err
  };
}




import {
  GET_PRESS_ERROR,
  GET_PRESS_REQUEST,
  GET_PRESS_SUCCESS,
} from 'redux/constants';

export function getPress(slug) {
  return {
    type: GET_PRESS_REQUEST,
    payload: slug,
  };
}

export function getPressSuccess(data) {
  return {
    type: GET_PRESS_SUCCESS,
    payload: data,
  };
}

export function getPressError(err) {
  return {
    type: GET_PRESS_ERROR,
    payload: err,
  };
}

import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  height: 38px;
  @media (max-width: 1199px) {
    .react-select-container {
      display: none;
    }
  }
`;

import { all, fork } from 'redux-saga/effects';

import landlords from 'redux/sagas/landlords';
import contactUsSagas from 'redux/sagas/contactUs';
import brokersInfoSagas from 'redux/sagas/brokers';
import homepageInfoSagas from 'redux/sagas/homepage';
import officeInfoSagas from 'redux/sagas/office';
import corporateClientsInfoSagas from 'redux/sagas/corporateClients';
import legalInfoSagas from 'redux/sagas/legal';
import footerSagas from 'redux/sagas/footer';
import aboutSagas from 'redux/sagas/about';
import helpSagas from 'redux/sagas/help';
import locationsSagas from 'redux/sagas/locations';
import contactPageSagas from 'redux/sagas/contactPage';
import filterDataSagas from 'redux/sagas/filterData';
import referralsSagas from 'redux/sagas/referral';
import servicesListInfoSagas from 'redux/sagas/servicesList';
import servicePageInfoSagas from 'redux/sagas/servicePage';
import pressSagas from 'redux/sagas/press';
import testimonialsSagas from 'redux/sagas/testimonials';

const sagas = [
  aboutSagas,
  landlords,
  contactUsSagas,
  brokersInfoSagas,
  homepageInfoSagas,
  officeInfoSagas,
  servicesListInfoSagas,
  corporateClientsInfoSagas,
  legalInfoSagas,
  footerSagas,
  helpSagas,
  locationsSagas,
  contactPageSagas,
  filterDataSagas,
  referralsSagas,
  servicePageInfoSagas,
  pressSagas,
  testimonialsSagas,
];

export function* rootSaga(services = {}) {
  yield all(sagas.map((saga) => fork(saga, services)));
}

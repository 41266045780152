import React, { Suspense, lazy, useEffect, useRef } from 'react';
import Container from '@material-ui/core/Container';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { history } from 'services/history';

import Header from 'components/common/Header';
import Footer from 'components/common/Footer';

import {
  ABOUT_ROUTE,
  BROKERS_ROUTE,
  CONTACT_US_ROUTE,
  COOKIE_POLICY_ROUTE,
  CORPORATE_CLIENTS_ROUTE,
  HELP_ROUTE,
  HOME_ROUTE,
  LANDLORDS_ROUTE,
  LOCATIONS_ROUTE,
  OFFICE_ROUTE,
  PRIVACY_POLICY_ROUTE,
  REFERRAL_ROUTE,
  TERMS_ROUTE,
  SERVICE_ROUTE,
} from 'constants/routes';

import { isLoading } from 'utils/helpers';
import { useHelpCrunchChat } from 'utils/hooks';
import { Loader } from 'components/common/Loader';
import { ScrollToTop } from 'components/common/ScrollTop';
import { scaleContent } from 'utils/helpers';
import MetaTags from 'components/common/MetaTags';
import CookiePolicyPopup from 'components/common/CookiePolicyPopup';

const HomePage = lazy(() => import('components/pages/Home'));
const HelpPage = lazy(() => import('components/pages/Help'));
const OfficePage = lazy(() => import('components/pages/Office'));
const BrokersPage = lazy(() => import('components/pages/Brokers'));
const LandlordsPage = lazy(() => import('components/pages/Landlords'));
const LocationsPage = lazy(() => import('components/pages/Locations'));
const PrivacyPolicyPage = lazy(() => import('components/pages/PrivacyPolicy'));
const CorporateClientsPage = lazy(() =>
  import('components/pages/CorporateClients')
);
const ContactUsPage = lazy(() => import('components/pages/ContactUs'));
const NoMatchPage = lazy(() => import('components/pages/NoMatch'));
const TermsAndConditions = lazy(() =>
  import('components/pages/TermsAndConditions')
);
const AboutPage = lazy(() => import('components/pages/About'));
const CookiePolicyPage = lazy(() => import('components/pages/CookiePolicy'));
const ReferralPage = lazy(() => import('components/pages/Referral'));
const Services = lazy(() => import('components/pages/Services'));

function Root() {
  const loading = useSelector((state) => isLoading(state));
  const wrapperRef = useRef();
  useEffect(() => {
    scaleContent(wrapperRef);
  }, []);

  useHelpCrunchChat();

  return (
    <Router
      history={history}
      /*forceRefresh={true}*/
    >
      <Container maxWidth='xl' ref={wrapperRef} disableGutters={true}>
        <MetaTags />
        <ScrollToTop />
        <Header history={history} />

        <Suspense fallback={<Loader visible={loading} />}>
          <Switch>
            {/* <Redirect from={OFFICE_ROUTE("dubai-dmcc")} to='/404'/> */}

            <Route exact strict path={HOME_ROUTE} component={HomePage} />
            <Route exact strict path={OFFICE_ROUTE()} component={OfficePage} />
            <Route exact strict path={BROKERS_ROUTE} component={BrokersPage} />
            <Route
              exact
              strict
              path={LANDLORDS_ROUTE}
              component={LandlordsPage}
            />
            <Route
              exact
              strict
              path={PRIVACY_POLICY_ROUTE}
              component={PrivacyPolicyPage}
            />
            <Route
              exact
              strict
              path={LOCATIONS_ROUTE}
              component={LocationsPage}
            />
            <Route exact strict path={HELP_ROUTE} component={HelpPage} />
            <Route
              exact
              strict
              path={CORPORATE_CLIENTS_ROUTE}
              component={CorporateClientsPage}
            />
            <Route
              exact
              strict
              path={CONTACT_US_ROUTE}
              component={ContactUsPage}
            />
            <Route
              exact
              strict
              path={COOKIE_POLICY_ROUTE}
              component={CookiePolicyPage}
            />
            <Route
              exact
              strict
              path={TERMS_ROUTE}
              component={TermsAndConditions}
            />
            <Route exact strict path={ABOUT_ROUTE} component={AboutPage} />
            <Route
              exact
              strict
              path={REFERRAL_ROUTE}
              component={ReferralPage}
            />
            <Route exact strict path={SERVICE_ROUTE()} component={Services} />
            <Route exact strict path='/error' component={NoMatchPage} />

            <Route exact strict>
              <Redirect push to='/error' />
            </Route>
          </Switch>
        </Suspense>

        <CookiePolicyPopup />
        <Footer />
      </Container>
      {/*<Loader visible={loading}/>*/}
    </Router>
  );
}

export default Root;

import React from "react";
import { useTranslation } from "react-i18next";

import "./index.scss";
import { LOGIN_ROUTE } from "constants/routes";

export default function Login() {
  const { t } = useTranslation();

  return (
    <a href={LOGIN_ROUTE} className="login">
      {t("HEADER.logIn")}
    </a>
  );
}

export const HELP_CRUNCH_SECRET = process.env.REACT_APP_HELP_CRUNCH_SECRET;

export const LANGUAGE_EN = 'en';
export const LANGUAGE_AR = 'ar';
// export const LANGUAGE_RU = 'ru';

export const RTL_LANGUAGES = [LANGUAGE_AR];
// export const LTR_LANGUAGES = [LANGUAGE_EN, LANGUAGE_RU];

export const DEFAULT_LANGUAGE = localStorage.i18next || LANGUAGE_EN;

export const LANGUAGE_ATTRIBUTE = {
  en: 'en-AE',
  ar: 'ar',
  ru: 'ru',
};

export const TIME_CHAT_INIT_MS = 5000;

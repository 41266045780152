import { fork, put, takeEvery } from "redux-saga/effects";
import { reset, untouch } from "redux-form";
import { delay } from "@redux-saga/core/effects";

import { POST_CONTACT_US_REQUEST } from "redux/constants";
import {
  postContactUsError,
  postContactUsSuccess,
  resetContactUs
} from "redux/actions";

import { getInstance } from "services/api";
import { CONTACT_US_API } from "constants/api";
import {CONTACT_US_FORM_NAME, MODAL_FORM_NAME, WORKSPACES_FORM_NAME} from "constants/forms";

function* watchContactUs() {
  yield takeEvery(POST_CONTACT_US_REQUEST, handlePostContactUs);
}

function* handlePostContactUs({ payload }) {
  try {
    const { type, ...rest } = payload;
    const { data } = yield getInstance().post(CONTACT_US_API, rest);
    yield put(postContactUsSuccess(data, type));
    yield put(reset(CONTACT_US_FORM_NAME));
    yield put(untouch(CONTACT_US_FORM_NAME));

    yield put(reset(MODAL_FORM_NAME));
    yield put(untouch(MODAL_FORM_NAME));

    yield put(reset(WORKSPACES_FORM_NAME));
    yield put(untouch(WORKSPACES_FORM_NAME));

    yield delay(5000);
    yield put(resetContactUs());
  } catch (e) {
    yield put(postContactUsError(e));
  }
}

export default function* contactUsSagas() {
  yield fork(watchContactUs);
}

import { SET_CURRENT_LANGUAGE } from "redux/constants";
import { options } from "constants/languageOptions";

const initialState = {
  language: options.find((el) => el.value === localStorage.i18next) || {
    value: "en",
    label: "English",
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_CURRENT_LANGUAGE:
      return { ...state, language: payload };

    default:
      return state;
  }
}

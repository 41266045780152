import React from "react";
import { Link } from "react-router-dom";

import { COOKIE_POLICY_ROUTE } from "constants/routes";
import Button from "components/common/Button";

import "./index.scss";
import {useTranslation} from "react-i18next";

export default function CookiePolicyPopup() {
  const isRead = localStorage.getItem("cookie");
  const { i18n, t } = useTranslation();

  function handleButtonClick() {
    const popup = document.querySelector(".cookie-popup");
    popup.classList.add("transition");
    localStorage.setItem("cookie", true);
  }

  return (
    <>
      {!isRead && (
        <div className="cookie-popup">
          <p>
            {t('LAW.cookies')}{" "}
            <Link to={COOKIE_POLICY_ROUTE}>{t('LAW.cookiesLink')}</Link>
          </p>
          <Button
            styleType="outlined"
            children={t('LAW.cookieBtn')}
            onClick={handleButtonClick}
          />
        </div>
      )}
    </>
  );
}

import {
  GET_LANDLORDS_INFO_ERROR,
  GET_LANDLORDS_INFO_REQUEST,
  GET_LANDLORDS_INFO_SUCCESS,
} from "redux/constants";

export function getLandlordsInfo() {
  return {
    type: GET_LANDLORDS_INFO_REQUEST
  };
}

export function getLandlordsInfoSuccess(data) {
  return {
    type: GET_LANDLORDS_INFO_SUCCESS,
    payload: data
  };
}

export function getLandlordsInfoError(err) {
  return {
    type: GET_LANDLORDS_INFO_ERROR,
    payload: err
  };
}




import {
  GET_ABOUT_INFO_ERROR,
  GET_ABOUT_INFO_REQUEST,
  GET_ABOUT_INFO_SUCCESS,
} from "redux/constants";

export function getAboutInfo() {
  return {
    type: GET_ABOUT_INFO_REQUEST,
  };
}

export function getAboutInfoSuccess(data) {
  return {
    type: GET_ABOUT_INFO_SUCCESS,
    payload: data,
  };
}

export function getAboutInfoError(err) {
  return {
    type: GET_ABOUT_INFO_ERROR,
    payload: err,
  };
}

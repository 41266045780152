import { fork, put, takeEvery } from "redux-saga/effects";

import { GET_HOMEPAGE_INFO_REQUEST } from "redux/constants";
import { getHomepageInfoError, getHomepageInfoSuccess } from "redux/actions";

import { getInstance } from "services/api";
import { HOMEPAGE_INFO_API } from "constants/api";

function* watchHomepageInfo() {
  yield takeEvery(GET_HOMEPAGE_INFO_REQUEST, handleGetHomepageInfo);
}

function* handleGetHomepageInfo() {
  try {
    const { data } = yield getInstance().get(HOMEPAGE_INFO_API);
    yield put(getHomepageInfoSuccess(data));
  } catch (e) {
    console.log(e)
    yield put(getHomepageInfoError(e));
  }
}

export default function* homepageInfoSagas() {
  yield fork(watchHomepageInfo);
}

import { fork, put, takeEvery } from "redux-saga/effects";

import { GET_LEGAL_INFO_REQUEST } from "redux/constants";
import { getLegalInfoError, getLegalInfoSuccess } from "redux/actions";

import { getInstance } from "services/api";
import { LEGAL_INFO_API } from "constants/api";

function* watchLegalInfo() {
  yield takeEvery(GET_LEGAL_INFO_REQUEST, handleGeLegalInfo);
}

function* handleGeLegalInfo({ payload }) {
  try {
    const { data } = yield getInstance().get(LEGAL_INFO_API(payload));
    yield put(getLegalInfoSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getLegalInfoError(error));
  }
}

export default function* legalInfoSagas() {
  yield fork(watchLegalInfo);
}

import {
  GET_LANDLORDS_INFO_ERROR,
  GET_LANDLORDS_INFO_SUCCESS
} from "redux/constants";

const initialState = {
  data: {
    blocks_titles: {
      gains_title: "",
      gains_subtitle: "",
      buildings_title: "",
      buildings_subtitle: "",
      gallery_title: "",
      gallery_subtitle: "",
      form_title: ""
    },
    header: {
      title: "",
      subtitle: "",
      image: ""
    },
    partnership: [],
    building: [],
    gallery: []
  },
  errors: null
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_LANDLORDS_INFO_SUCCESS:
      return { ...state, data: payload, errors: null };
    case GET_LANDLORDS_INFO_ERROR:
      return { ...state, errors: payload, data: [] };

    default:
      return state;
  }
}

import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const Checkbox = ({ id, meta, hideErrors, input, ...props }) => {
  const { t } = useTranslation();

  return (
    <>
      <input {...input} id={id} type='checkbox' {...props} />
      <label htmlFor={id}>
        <Trans i18nKey='FORM.terms'>
          <span className='terms'>
            {t('FORM.terms_first')}
            <Link
              to='/terms-conditions'
              style={{ textDecoration: 'none', color: '#ca9263' }}
            >
              {t('FORM.terms_second')}
            </Link>
            {t('FORM.terms_third')}
          </span>
        </Trans>
      </label>
      <div className='error-wrapper'>
        {meta.touched &&
          meta.error &&
          !hideErrors &&
          typeof meta.error !== 'object' && (
            <span className='form-error'>{meta.error}</span>
          )}
      </div>
    </>
  );
};

export default Checkbox;

import {
  GET_CORPORATE_CLIENTS_INFO_ERROR,
  GET_CORPORATE_CLIENTS_INFO_REQUEST,
  GET_CORPORATE_CLIENTS_INFO_SUCCESS,
} from "redux/constants";

export function getCorporateClientsInfo() {
  return {
    type: GET_CORPORATE_CLIENTS_INFO_REQUEST,
  };
}

export function getCorporateClientsInfoSuccess(data) {
  return {
    type: GET_CORPORATE_CLIENTS_INFO_SUCCESS,
    payload: data,
  };
}

export function getCorporateClientsInfoError(err) {
  return {
    type: GET_CORPORATE_CLIENTS_INFO_ERROR,
    payload: err,
  };
}

import { GET_PRESS_ERROR, GET_PRESS_SUCCESS } from 'redux/constants';

const initialState = {
  data: {
    title: '',
    link: '',
    logo_image: '',
    order: null,
  },
  errors: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PRESS_SUCCESS:
      return { ...state, data: payload, errors: null };
    case GET_PRESS_ERROR:
      return { ...state, errors: payload, data: [] };

    default:
      return state;
  }
}

import React, { memo } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import './index.scss';
import { options } from 'constants/languageOptions';
import { setCurrentLanguage } from 'redux/actions';
import { isRtl } from 'utils/helpers';

export const LanguageSelect = memo((props) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const { language } = useSelector((state) => state.language);

  const changeLanguage = (lang) => i18n.changeLanguage(lang);

  const handleChange = (selectedOption) => {
    document.dir = isRtl(selectedOption.value) ? 'rtl' : 'ltr';
    changeLanguage(selectedOption.value);
    dispatch(setCurrentLanguage(selectedOption));
  };

  return (
    <Select
      className={`react-select-container ${props.footer ? 'footer' : ''}`}
      classNamePrefix='react-select'
      value={language}
      onChange={handleChange}
      options={options}
      isRtl={false}
      isSearchable={false}
      menuPlacement='auto'
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      getOptionLabel={(item) => (
        <div className='react-select__item-language'>
          <span>{item.label}</span>
          {item.icon}
        </div>
      )}
    />
  );
});

export default LanguageSelect;

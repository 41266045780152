import { fork, put, takeEvery } from "redux-saga/effects";

import { GET_REFERRALS_INFO_REQUEST } from "redux/constants";
import { getReferralsInfoError, getReferralsInfoSuccess } from "redux/actions";

import { getInstance } from "services/api";
import { REFERRALS_API } from "constants/api";

function* watchOfficeInfo() {
  yield takeEvery(GET_REFERRALS_INFO_REQUEST, handleGetReferralsInfo);
}

function* handleGetReferralsInfo() {
  try {
    const { data } = yield getInstance().get(REFERRALS_API);
    yield put(getReferralsInfoSuccess(data));
  } catch (error) {
    yield put(getReferralsInfoError(error));
  }
}

export default function* referralsInfoSagas() {
  yield fork(watchOfficeInfo);
}

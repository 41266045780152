import React from "react";
import { Wrapper } from "./style";
import Login from "components/common/Header/root/content/actions/login";
import LanguageSelect from "components/common/LanguageSelect";

export default function Actions() {
  return (
    <Wrapper>
      <LanguageSelect />
      <Login />
    </Wrapper>
  );
}

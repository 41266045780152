import styled from 'styled-components';
import Container from '@material-ui/core/Container';

export const InfoContainer = styled('div')`
  font-family: 'Din Pro';
  width: 461px;
  // height: 157px;
  background: rgba(68, 68, 79, 0.4);
  backdrop-filter: blur(20px);
  padding: 24px 22px 28px 22px;

  @media screen and (max-width: 768px) {
    text-align: center;
  }

  .title {
    color: white;

    &.rtl p {
      direction: ltr;

      @media screen and (min-width: 768px) {
        text-align: right;
      }
    }
  }

  h2 {
    font-weight: 100;
    font-size: 42px;
    color: white;
    margin: 0;

    strong {
      font-weight: 300;
    }
    @media screen and (max-width: 768px) {
      font-size: 32px;
      //font-weight: 400;

      strong {
        font-weight: 400;
      }
    }
  }

  p {
    font-weight: 600;
    margin: 0;
    font-size: 24px;
    color: white;

    @media screen and (max-width: 768px) {
      font-size: 22px;
    }
  }
`;

export const MainContainer = styled(Container)`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  @media (max-width: 500px) {
    height: 534px;
  }
`;

export const FormWrapper = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;

  .MuiExpansionPanelSummary-content {
    margin: 0;
    h2.title {
      font-family: DIN Pro;
      font-weight: 300;
      font-size: 26px;
      line-height: 28px;
      color: #ffffff;
      svg {
        cursor: pointer;
      }
    }
  }

  .back-color {
    background: transparent !important;
    backdrop-filter: blur(0px) !important;
    padding: 0 !important;
  }

  form.form {
    position: sticky;
    padding-top: 20px;
    background: rgba(33, 76, 68, 0.9);
    backdrop-filter: blur(20px);
    width: 100%;
    padding: 34px 28px;
    display: flex;
    flex-direction: column;
    @media (min-width: 1920px) {
      position: relative;
      top: 0;
    }

    .title {
      margin-bottom: 0;
      padding-bottom: 6px;

      font-family: 'Din Pro';
      font-weight: 600;
      font-size: 26px;
      line-height: 28px;
      /* identical to box height, or 108% */
      color: #ffffff;
    }
    .description {
      padding-bottom: 30px;

      font-family: Open Sans;
      font-weight: 400;
      font-size: 14px;
      line-height: 180%;
      /* or 25px */
      color: #ffffff;
    }

    label {
      display: flex;

      .number {
        border-left: 1px solid rgba(255, 255, 255, 0.2);
      }
    }

    input {
      width: 100%;
      height: 44px;
      border: none;

      margin-bottom: 10px;
      text-indent: 21px;

      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #ffffff;
      background-color: rgba(255, 255, 255, 0.2);

      &:focus {
        outline: none;
        outline: -webkit-focus-ring-color auto 1px;
      }
    }

    input::placeholder {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #f2f2f2;
    }

    textarea {
      resize: none;
      width: 100%;
      height: 88px;
      border: none;

      // margin-bottom: 30px;
      margin-bottom: 10px;
      padding-top: 9px;
      text-indent: 21px;
      padding-right: 21px;

      font-family: Open Sans;
      font-weight: normal;
      font-size: 14px;
      color: #ffffff;
      background-color: rgba(255, 255, 255, 0.2);
    }

    textarea::placeholder {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #f2f2f2;
    }

    button[type='submit'] {
      padding-top: 5px;
      padding-bottom: 3px;
      background-color: transparent;
      border: 1px solid #ffffff;

      font-family: DIN Pro;
      font-weight: 300;
      font-size: 20px;
      line-height: 25px;
      text-transform: uppercase;
      color: #ffffff;
    }

    @media (max-width: 500px) {
      margin-top: 0px;
      padding: 10px 10px;
    }
  }

  .checkboxContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    margin-left: -4px;

    .checkbox {
      min-width: 24px;
      min-height: 24px;
      max-height: 24px;
      max-width: 24px;
      position: absolute;
      z-index: -1;
      opacity: 0;
    }
    .checkbox + label {
      display: inline-flex;
      padding-bottom: 5px;
      align-items: center;
      cursor: pointer;
      user-select: none;
    }
    .checkbox + label::before {
      content: '';
      display: inline-block;
      width: 30px;
      height: 30px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid #ca9263;
      border-radius: 0.25em;
      margin-right: 0.5em;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
    }
    .checkbox:checked + label::before {
      border-color: #ca9263;
      background-color: #ca9263;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }

    /* styles for the active state of the checkbox (when you click on it) */
    .custom-checkbox:not(:disabled):active + label::before {
      background-color: #b3d7ff;
      border-color: #b3d7ff;
    }

    .terms {
      margin-left: 4px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
      color: #ffffff;
      @media screen and (max-width: 800px) {
        font-size: 14px;
      }
    }

    .error-wrapper {
      width: 100%;
    }
  }
`;

import React from 'react';
import './index.scss';

import { ReactComponent as ThePlaceSvg } from 'assets/svg/the-place-text.svg';
import { useTranslation } from 'react-i18next';

const keyword = '**theplace**';

export default function Placeify({ children, font, mode }) {
  const { i18n } = useTranslation();

  let childrenArray =
    typeof children === 'string' ? children.split(keyword) : children;
  function returnSpanOfNull(el, index) {
    return el ? (
      <span
        className='placify-span'
        key={index}
        dangerouslySetInnerHTML={{ __html: el }}
      />
    ) : null;
  }
  childrenArray =
    childrenArray && childrenArray.length
      ? childrenArray.map((el, index, arr) => {
          if (index !== arr.length - 1) {
            return [
              returnSpanOfNull(el, index),
              <ThePlaceSvg
                key={index + 0.5}
                className={
                  'the-place-text-svg ' + (font || '') + ' ' + (mode || '')
                }
              />,
            ];
          } else
            return (
              <span
                className='placify-span'
                key={index}
                dangerouslySetInnerHTML={{ __html: el }}
              />
            );
        })
      : [];
  childrenArray = childrenArray.flat();
  return childrenArray;
}

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import Input from 'components/common/Input';
import PhonePrefix from 'components/common/PhonePrefix';
import Placeify from 'components/common/Placeify';
import Button from 'components/common/Button';
import Captcha from 'components/common/Captcha';
import ThankYou from 'components/common/ContactUs/thankYou';
import Textarea from 'components/common/Textarea';
import { useInView } from 'react-intersection-observer';
import { FormWrapper } from './styled';

import { CONTACT_US_FORM_NAME } from 'constants/forms';
import {
  isNumber,
  isRequired,
  maxLength,
  maxNumberLength,
  isLength5,
} from 'utils/validators';
import Checkbox from 'components/common/Checkbox';

const ContactUsC = ({
  textAreaType,
  title,
  description,
  cityInterest,
  sqft,
  teamSize,
  companyName,
  handleSubmit,
  formMobile,
  checkboxId = 'terms',
}) => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  return (
    <FormWrapper>
      <ThankYou />

      <form
        className={`form ${formMobile ? 'back-color' : ''}`}
        onSubmit={handleSubmit}
        ref={ref}
      >
        <div className='title'>
          <Placeify>{title}</Placeify>
        </div>
        {description && (
          <div className='description'>
            <span>{description}</span>
          </div>
        )}

        <Field
          placeholder={t('FORM.full name')}
          name='fullName'
          type='text'
          component={Input}
          validate={[isRequired]}
          normalize={maxLength(25)}
        />

        {companyName && (
          <Field
            placeholder={t('FORM.company name')}
            name='companyName'
            type='text'
            component={Input}
            validate={[isRequired]}
          />
        )}

        <Field
          placeholder={t('FORM.email')}
          name='email'
          type='text'
          component={Input}
          validate={[isRequired]}
        />

        <Grid container spacing={1}>
          <Grid container item xs>
            <Grid item xs={5}>
              <Field
                name='prefix'
                component={PhonePrefix}
                validate={[isRequired]}
              />
            </Grid>
            <Grid item xs>
              <Field
                className='number'
                placeholder={t('FORM.phone number')}
                name='phoneNumber'
                type='text'
                component={Input}
                validate={[isRequired, isLength5]}
                normalize={maxNumberLength(15)}
              />
            </Grid>
          </Grid>

          {teamSize === 'small' && (
            <Grid item xs={12}>
              <Field
                placeholder={t('FORM.team size')}
                name='teamSize'
                type='text'
                component={Input}
                validate={[isRequired]}
                normalize={maxNumberLength(5)}
              />
            </Grid>
          )}
        </Grid>

        {teamSize === 'full' && (
          <Field
            placeholder={t('FORM.team size')}
            name='teamSize'
            type='text'
            component={Input}
            validate={[isRequired]}
            normalize={maxNumberLength(5)}
          />
        )}

        {cityInterest && (
          <Grid container spacing={1}>
            <Grid container item xs>
              <Grid item xs>
                <Field
                  className='number'
                  placeholder={t('FORM.city interest')}
                  name='cityInterest'
                  type='text'
                  component={Input}
                  validate={[isRequired]}
                />
              </Grid>
            </Grid>

            {sqft && (
              <Grid item xs={4}>
                <Field
                  placeholder={t('FORM.sqft')}
                  name='squareFeet'
                  type='text'
                  component={Input}
                  validate={[isRequired, isNumber]}
                />
              </Grid>
            )}
          </Grid>
        )}

        {textAreaType === 'comments' && (
          <Field
            placeholder={t('FORM.comments')}
            name='comments'
            type='text'
            component={Textarea}
            validate={[isRequired]}
            maxLength={500}
          />
        )}

        {textAreaType === 'budget' && (
          <Field
            placeholder={t('FORM.budget & additional requirements')}
            name='budget'
            type='text'
            component={Textarea}
            validate={[isRequired]}
            maxLength={500}
          />
        )}

        {inView ? (
          <Field
            name='g-recaptcha-response'
            component={Captcha}
            validate={[isRequired]}
          />
        ) : null}

        <div className='checkboxContainer'>
          <Field
            name={checkboxId}
            id={checkboxId}
            component={Checkbox}
            validate={[isRequired]}
            className='checkbox'
          />
        </div>

        <Button size='s'>{t('BUTTONS.send')}</Button>
      </form>
    </FormWrapper>
  );
};

export const ContactUsForm = reduxForm({
  form: CONTACT_US_FORM_NAME,
  enableReinitialize: true,
})(ContactUsC);

import axios from "axios";

const REQUEST_TIMEOUT = 10000;

export const getInstance = () =>
  axios.create({
    timeout: REQUEST_TIMEOUT,
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "max-age=31536000",
    },
  });

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { BASE_URL } from 'constants/api';
import { HELP_CRUNCH_SECRET } from 'constants/settings';
import { TIME_CHAT_INIT_MS } from 'constants/settings';

export const useApiData = (action) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  // change default BASE_URL based on current language
  axios.defaults.baseURL = `${BASE_URL}${i18n.language}`;

  const [lastLang, setLastLang] = useState(null);

  const detectAction = typeof action === 'function' ? action() : action;

  useEffect(() => {
    if (lastLang !== i18n.language) {
      setLastLang(i18n.language);
      dispatch(detectAction);
    }
  }, [lastLang, i18n.language, dispatch, detectAction]);
};

export const useHelpCrunchChat = (url) => {
  useEffect(() => {
    window.HelpCrunch = function () {
      window.HelpCrunch.q.push(arguments);
    };

    window.HelpCrunch.q = [];

    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://widget.helpcrunch.com/';
    scriptTag.async = true;

    setTimeout(() => {
      document.body.appendChild(scriptTag);
      scriptTag.onload = () => {
        window.HelpCrunch('init', 'theplace', {
          applicationId: 1,
          applicationSecret: HELP_CRUNCH_SECRET,
        });

        window.HelpCrunch('showChatWidget');
      };
    }, TIME_CHAT_INIT_MS);
  }, [url]);
};

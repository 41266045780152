import React, { Component } from "react";
import { ReactComponent as LogoSvg } from "assets/svg/the-place-big.svg";
import { ReactComponent as MobileLogoSvg } from "assets/svg/the-place-text.svg";
import PropTypes from "prop-types";
import "./index.scss";

export default class Logo extends Component {
  render() {
    return (
      <>
        {window.innerWidth > 600 ? (
          <LogoSvg className={this.props.className} />
        ) : (
          <MobileLogoSvg className={this.props.className} />
        )}
      </>
    );
  }
}

Logo.propTypes = { className: PropTypes.string };

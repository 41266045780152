import {resetContactUs} from "redux/actions";
import TagManager from 'react-gtm-module'
import {uniqueID} from "utils/functions";

export function downloadAndroidApp() {
    const tagManagerArgs = {
        gtmId: 'GTM-TBJ6ZDQ',
        events: {
            event: "download_google_play"
        }
    }
    TagManager.initialize(tagManagerArgs)
}

export function downloadIOSApp() {
    const tagManagerArgs = {
        gtmId: 'GTM-TBJ6ZDQ',
        events: {
            event: "download_app_store"
        }
    }
    TagManager.initialize(tagManagerArgs)
}

export function formRequestSuccess(type = '') {
    const currentLocation = window.location.pathname;

    if (currentLocation === '/') {
        const tagManagerArgs = {
            gtmId: 'GTM-TBJ6ZDQ',
            // google analytics event
            events: {
                event: "contact_button"
            },
        }

        const dataLayer = {
            // google tag manager event
            dataLayer: {
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: uniqueID(),
                            products: [
                                {
                                    name: 'ContactUsForm',
                                    id: '1',
                                    price: '0',
                                    quantity: 1,
                                },
                            ],
                        },
                    },
                },
                event: 'gtm-ee-event',
                'gtm-ee-event-category': 'Enhanced Ecommerce',
                'gtm-ee-event-action': 'Purchase',
                'gtm-ee-event-non-interaction': 'False',
            },
            dataLayerName: 'PageDataLayer',
        };

        TagManager.initialize(tagManagerArgs)
        TagManager.dataLayer(dataLayer)
    }

    if (currentLocation === '/contact-us') {
        const tagManagerArgs = {
            gtmId: 'GTM-TBJ6ZDQ',
            // google analytics event
            events: {
                event: "contact_button"
            },
        }

        const dataLayer = {
            // google tag manager event
            dataLayer: {
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: uniqueID(),
                            products: [
                                {
                                    name: 'ContactUsForm',
                                    id: '1',
                                    price: '0',
                                    quantity: 1,
                                },
                            ],
                        },
                    },
                },
                event: 'gtm-ee-event',
                'gtm-ee-event-category': 'Enhanced Ecommerce',
                'gtm-ee-event-action': 'Purchase',
                'gtm-ee-event-non-interaction': 'False',
            },
            dataLayerName: 'PageDataLayer',
        };

        TagManager.initialize(tagManagerArgs)
        TagManager.dataLayer(dataLayer)
    }

    if (currentLocation === '/referral') {
        const tagManagerArgs = {
            gtmId: 'GTM-TBJ6ZDQ',
            // google analytics event
            events: {
                event: "contact_button"
            },
        }

        const dataLayer = {
            // google tag manager event
            dataLayer: {
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: uniqueID(),
                            products: [
                                {
                                    name: 'ContactUsForm',
                                    id: '1',
                                    price: '0',
                                    quantity: 1,
                                },
                            ],
                        },
                    },
                },
                event: 'gtm-ee-event',
                'gtm-ee-event-category': 'Enhanced Ecommerce',
                'gtm-ee-event-action': 'Purchase',
                'gtm-ee-event-non-interaction': 'False',
            },
            dataLayerName: 'PageDataLayer',
        };

        TagManager.initialize(tagManagerArgs)
        TagManager.dataLayer(dataLayer)
    }

    if (currentLocation === '/locations') {
        const tagManagerArgs = {
            gtmId: 'GTM-TBJ6ZDQ',
            // google analytics event
            events: {
                event: "contact_button"
            },
        }

        const dataLayer = {
            // google tag manager event
            dataLayer: {
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: uniqueID(),
                            products: [
                                {
                                    name: 'ContactUsForm',
                                    id: '1',
                                    price: '0',
                                    quantity: 1,
                                },
                            ],
                        },
                    },
                },
                event: 'gtm-ee-event',
                'gtm-ee-event-category': 'Enhanced Ecommerce',
                'gtm-ee-event-action': 'Purchase',
                'gtm-ee-event-non-interaction': 'False',
            },
            dataLayerName: 'PageDataLayer',
        };

        TagManager.initialize(tagManagerArgs)
        TagManager.dataLayer(dataLayer)
    }

    if (currentLocation === '/corporate-clients') {
        const tagManagerArgs = {
            gtmId: 'GTM-TBJ6ZDQ',
            // google analytics event
            events: {
                event: "contact_button"
            },
        }

        const dataLayer = {
            // google tag manager event
            dataLayer: {
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: uniqueID(),
                            products: [
                                {
                                    name: 'ContactUsForm',
                                    id: '1',
                                    price: '0',
                                    quantity: 1,
                                },
                            ],
                        },
                    },
                },
                event: 'gtm-ee-event',
                'gtm-ee-event-category': 'Enhanced Ecommerce',
                'gtm-ee-event-action': 'Purchase',
                'gtm-ee-event-non-interaction': 'False',
            },
            dataLayerName: 'PageDataLayer',
        };

        TagManager.initialize(tagManagerArgs)
        TagManager.dataLayer(dataLayer)
    }

    if (currentLocation === '/landlords') {
        const tagManagerArgs = {
            gtmId: 'GTM-TBJ6ZDQ',
            // google analytics event
            events: {
                event: "contact_button"
            },
        }

        const dataLayer = {
            // google tag manager event
            dataLayer: {
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: uniqueID(),
                            products: [
                                {
                                    name: 'ContactUsForm',
                                    id: '1',
                                    price: '0',
                                    quantity: 1,
                                },
                            ],
                        },
                    },
                },
                event: 'gtm-ee-event',
                'gtm-ee-event-category': 'Enhanced Ecommerce',
                'gtm-ee-event-action': 'Purchase',
                'gtm-ee-event-non-interaction': 'False',
            },
            dataLayerName: 'PageDataLayer',
        };

        TagManager.initialize(tagManagerArgs)
        TagManager.dataLayer(dataLayer)
    }

    if (currentLocation === '/help') {
        const tagManagerArgs = {
            gtmId: 'GTM-TBJ6ZDQ',
            // google analytics event
            events: {
                event: "contact_button"
            },
        }

        const dataLayer = {
            // google tag manager event
            dataLayer: {
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: uniqueID(),
                            products: [
                                {
                                    name: 'ContactUsForm',
                                    id: '1',
                                    price: '0',
                                    quantity: 1,
                                },
                            ],
                        },
                    },
                },
                event: 'gtm-ee-event',
                'gtm-ee-event-category': 'Enhanced Ecommerce',
                'gtm-ee-event-action': 'Purchase',
                'gtm-ee-event-non-interaction': 'False',
            },
            dataLayerName: 'PageDataLayer',
        };

        TagManager.initialize(tagManagerArgs)
        TagManager.dataLayer(dataLayer)
    }

    if (currentLocation === '/brokers') {
        const tagManagerArgs = {
            gtmId: 'GTM-TBJ6ZDQ',
            // google analytics event
            events: {
                event: "contact_button"
            },
        }

        const dataLayer = {
            // google tag manager event
            dataLayer: {
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: uniqueID(),
                            products: [
                                {
                                    name: 'ContactUsForm',
                                    id: '1',
                                    price: '0',
                                    quantity: 1,
                                },
                            ],
                        },
                    },
                },
                event: 'gtm-ee-event',
                'gtm-ee-event-category': 'Enhanced Ecommerce',
                'gtm-ee-event-action': 'Purchase',
                'gtm-ee-event-non-interaction': 'False',
            },
            dataLayerName: 'PageDataLayer',
        };

        TagManager.initialize(tagManagerArgs)
        TagManager.dataLayer(dataLayer)
    }

    if (currentLocation === '/office/the-one-tower') {
        switch (type) {
            case 'Event venues': {
                const tagManagerArgs = {
                    gtmId: 'GTM-TBJ6ZDQ',
                    events: {
                        event: "booking"
                    }
                }

                const dataLayer = {
                    // google tag manager event
                    dataLayer: {
                        ecommerce: {
                            purchase: {
                                actionField: {
                                    id: uniqueID(),
                                    products: [
                                        {
                                            name: 'Booking',
                                            id: '4',
                                            price: '0',
                                            quantity: 1,
                                        },
                                    ],
                                },
                            },
                        },
                        event: 'gtm-ee-event',
                        'gtm-ee-event-category': 'Enhanced Ecommerce',
                        'gtm-ee-event-action': 'Purchase',
                        'gtm-ee-event-non-interaction': 'False',
                    },
                    dataLayerName: 'PageDataLayer',
                };

                TagManager.initialize(tagManagerArgs)
                TagManager.dataLayer(dataLayer)
            }
                break;
            default: {
                const tagManagerArgs = {
                    gtmId: 'GTM-TBJ6ZDQ',
                    // google analytics event
                    events: {
                        event: "contact_button"
                    },
                }

                const dataLayer = {
                    // google tag manager event
                    dataLayer: {
                        ecommerce: {
                            purchase: {
                                actionField: {
                                    id: uniqueID(),
                                    products: [
                                        {
                                            name: 'ContactUsForm',
                                            id: '1',
                                            price: '0',
                                            quantity: 1,
                                        },
                                    ],
                                },
                            },
                        },
                        event: 'gtm-ee-event',
                        'gtm-ee-event-category': 'Enhanced Ecommerce',
                        'gtm-ee-event-action': 'Purchase',
                        'gtm-ee-event-non-interaction': 'False',
                    },
                    dataLayerName: 'PageDataLayer',
                };

                TagManager.initialize(tagManagerArgs)
                TagManager.dataLayer(dataLayer)
            }

        }

    }

    if (currentLocation === '/office/dubai-dmcc') {
        if (type == 'Private offices' || type == 'Coworking stations' || type == 'Event venues'){
            const tagManagerArgs = {
                gtmId: 'GTM-TBJ6ZDQ',
                events: {
                    event: "booking"
                }
            }

            const dataLayer = {
                // google tag manager event
                dataLayer: {
                    ecommerce: {
                        purchase: {
                            actionField: {
                                id: uniqueID(),
                                products: [
                                    {
                                        name: 'Booking',
                                        id: '4',
                                        price: '0',
                                        quantity: 1,
                                    },
                                ],
                            },
                        },
                    },
                    event: 'gtm-ee-event',
                    'gtm-ee-event-category': 'Enhanced Ecommerce',
                    'gtm-ee-event-action': 'Purchase',
                    'gtm-ee-event-non-interaction': 'False',
                },
                dataLayerName: 'PageDataLayer',
            };

            TagManager.initialize(tagManagerArgs)
            TagManager.dataLayer(dataLayer)
        } else {
            const tagManagerArgs = {
                gtmId: 'GTM-TBJ6ZDQ',
                // google analytics event
                events: {
                    event: "contact_button"
                },
            }

            const dataLayer = {
                // google tag manager event
                dataLayer: {
                    ecommerce: {
                        purchase: {
                            actionField: {
                                id: uniqueID(),
                                products: [
                                    {
                                        name: 'ContactUsForm',
                                        id: '1',
                                        price: '0',
                                        quantity: 1,
                                    },
                                ],
                            },
                        },
                    },
                    event: 'gtm-ee-event',
                    'gtm-ee-event-category': 'Enhanced Ecommerce',
                    'gtm-ee-event-action': 'Purchase',
                    'gtm-ee-event-non-interaction': 'False',
                },
                dataLayerName: 'PageDataLayer',
            };

            TagManager.initialize(tagManagerArgs)
            TagManager.dataLayer(dataLayer)
        }
    }

    if (currentLocation === '/virtual-office') {
        const tagManagerArgs = {
            gtmId: 'GTM-TBJ6ZDQ',
            // google analytics event
            events: {
                event: "contact_button"
            },
        }

        const dataLayer = {
            // google tag manager event
            dataLayer: {
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: uniqueID(),
                            products: [
                                {
                                    name: 'ContactUsForm',
                                    id: '1',
                                    price: '0',
                                    quantity: 1,
                                },
                            ],
                        },
                    },
                },
                event: 'gtm-ee-event',
                'gtm-ee-event-category': 'Enhanced Ecommerce',
                'gtm-ee-event-action': 'Purchase',
                'gtm-ee-event-non-interaction': 'False',
            },
            dataLayerName: 'PageDataLayer',
        };

        TagManager.initialize(tagManagerArgs)
        TagManager.dataLayer(dataLayer)
    }

    if (currentLocation === '/coworking-space') {
        const tagManagerArgs = {
            gtmId: 'GTM-TBJ6ZDQ',
            // google analytics event
            events: {
                event: "contact_button"
            },
        }

        const dataLayer = {
            // google tag manager event
            dataLayer: {
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: uniqueID(),
                            products: [
                                {
                                    name: 'ContactUsForm',
                                    id: '1',
                                    price: '0',
                                    quantity: 1,
                                },
                            ],
                        },
                    },
                },
                event: 'gtm-ee-event',
                'gtm-ee-event-category': 'Enhanced Ecommerce',
                'gtm-ee-event-action': 'Purchase',
                'gtm-ee-event-non-interaction': 'False',
            },
            dataLayerName: 'PageDataLayer',
        };

        TagManager.initialize(tagManagerArgs)
        TagManager.dataLayer(dataLayer)
    }

    if (currentLocation === '/private-offices') {
        const tagManagerArgs = {
            gtmId: 'GTM-TBJ6ZDQ',
            // google analytics event
            events: {
                event: "contact_button"
            },
        }

        const dataLayer = {
            // google tag manager event
            dataLayer: {
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: uniqueID(),
                            products: [
                                {
                                    name: 'ContactUsForm',
                                    id: '1',
                                    price: '0',
                                    quantity: 1,
                                },
                            ],
                        },
                    },
                },
                event: 'gtm-ee-event',
                'gtm-ee-event-category': 'Enhanced Ecommerce',
                'gtm-ee-event-action': 'Purchase',
                'gtm-ee-event-non-interaction': 'False',
            },
            dataLayerName: 'PageDataLayer',
        };

        TagManager.initialize(tagManagerArgs)
        TagManager.dataLayer(dataLayer)
    }

    if (currentLocation === '/event-spaces') {
        const tagManagerArgs = {
            gtmId: 'GTM-TBJ6ZDQ',
            // google analytics event
            events: {
                event: "contact_button"
            },
        }

        const dataLayer = {
            // google tag manager event
            dataLayer: {
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: uniqueID(),
                            products: [
                                {
                                    name: 'ContactUsForm',
                                    id: '1',
                                    price: '0',
                                    quantity: 1,
                                },
                            ],
                        },
                    },
                },
                event: 'gtm-ee-event',
                'gtm-ee-event-category': 'Enhanced Ecommerce',
                'gtm-ee-event-action': 'Purchase',
                'gtm-ee-event-non-interaction': 'False',
            },
            dataLayerName: 'PageDataLayer',
        };

        TagManager.initialize(tagManagerArgs)
        TagManager.dataLayer(dataLayer)
    }

    if (currentLocation === '/meeting-rooms') {
        const tagManagerArgs = {
            gtmId: 'GTM-TBJ6ZDQ',
            // google analytics event
            events: {
                event: "contact_button"
            },
        }

        const dataLayer = {
            // google tag manager event
            dataLayer: {
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: uniqueID(),
                            products: [
                                {
                                    name: 'ContactUsForm',
                                    id: '1',
                                    price: '0',
                                    quantity: 1,
                                },
                            ],
                        },
                    },
                },
                event: 'gtm-ee-event',
                'gtm-ee-event-category': 'Enhanced Ecommerce',
                'gtm-ee-event-action': 'Purchase',
                'gtm-ee-event-non-interaction': 'False',
            },
            dataLayerName: 'PageDataLayer',
        };

        TagManager.initialize(tagManagerArgs)
        TagManager.dataLayer(dataLayer)
    }

    const timer = setTimeout(() => resetContactUs(), 10000);
    return () => clearTimeout(timer);
}

export function bookingTour () {
    const tagManagerArgs = {
        gtmId: 'GTM-TBJ6ZDQ',
        events: {
            event: "booking_tour"
        }
    }

    const dataLayer = {
        // google tag manager event
        dataLayer: {
            ecommerce: {
                purchase: {
                    actionField: {
                        id: uniqueID(),
                        products: [
                            {
                                name: 'BookTourForm',
                                id: '3',
                                price: '0',
                                quantity: 1,
                            },
                        ],
                    },
                },
            },
            event: 'gtm-ee-event',
            'gtm-ee-event-category': 'Enhanced Ecommerce',
            'gtm-ee-event-action': 'Purchase',
            'gtm-ee-event-non-interaction': 'False',
        },
        dataLayerName: 'PageDataLayer',
    };

    TagManager.initialize(tagManagerArgs)
    TagManager.dataLayer(dataLayer)
}

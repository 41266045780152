import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import { combineReducers } from 'redux';

import apiReducer from 'redux/reducers/api';
import officeReducer from 'redux/reducers/office';
import brokersReducer from 'redux/reducers/brokers';
import homepageReducer from 'redux/reducers/homepage';
import locationsReducer from 'redux/reducers/locations';
import contactUsReducer from 'redux/reducers/contactUs';
import landlordsReducer from 'redux/reducers/landlords';
import corporateClientsReducer from 'redux/reducers/corporateClients';
import languageReducer from 'redux/reducers/language';
import legalReducer from 'redux/reducers/legal';
import footerReducer from 'redux/reducers/footer';
import aboutReducer from 'redux/reducers/about';
import helpReducer from 'redux/reducers/help';
import contactPageReducer from 'redux/reducers/contactPage';
import filterDataReducer from 'redux/reducers/filterData';
import referralsReducer from 'redux/reducers/referral';
import ServicesListReducer from 'redux/reducers/servicesList';
import ServicePageReducer from 'redux/reducers/servicePage';
import press from 'redux/reducers/press';
import testimonials from 'redux/reducers/testimonials';

export const rootReducer = (history) =>
  combineReducers({
    api: apiReducer,
    language: languageReducer,
    router: connectRouter(history),
    form: formReducer,
    office: officeReducer,
    brokers: brokersReducer,
    homepage: homepageReducer,
    locations: locationsReducer,
    landlords: landlordsReducer,
    contactUs: contactUsReducer,
    corporateClients: corporateClientsReducer,
    legal: legalReducer,
    footer: footerReducer,
    about: aboutReducer,
    help: helpReducer,
    contactPage: contactPageReducer,
    filterData: filterDataReducer,
    referrals: referralsReducer,
    servicesList: ServicesListReducer,
    servicePage: ServicePageReducer,
    press: press,
    testimonials: testimonials,
  });

import React from 'react';


const Input = ({ loading, label, meta, hideErrors, input, ...props }) => {
  return (
    <>
      <input {...input} value={label ? label : input.value} {...props} />

      {meta.touched && meta.error && !hideErrors && typeof meta.error !== 'object' && (
        <span className="form-error">{meta.error}</span>
      )}
    </>
  );
};

export default Input;

import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const CaptchaWrapper = styled("div")`
  margin: 10px auto 20px auto;
`;

const Captcha = ({ meta, ...props }) => {
    const { i18n } = useTranslation();

    return (
        <CaptchaWrapper>
            <ReCAPTCHA
                sitekey='6Lfq_foUAAAAACcnb8FUYfMl9EXXO7Z1dZ2pQ452'
                onChange={props.input.onChange}
                hl={i18n.language}
            />
            {meta && meta.touched && meta.error && (
                <span className='form-error'>{meta.error}</span>
            )}
        </CaptchaWrapper>
    );
}

export default Captcha;

import { GET_LEGAL_INFO_ERROR, GET_LEGAL_INFO_SUCCESS } from "redux/constants";

const initialState = {
  data: {
    title: null,
    subtitle: null,
    content: null,
    updated: null,
  },

  errors: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_LEGAL_INFO_SUCCESS:
      return { ...state, data: payload.data, errors: null };
    case GET_LEGAL_INFO_ERROR:
      return { ...state, errors: payload, data: [] };

    default:
      return state;
  }
}

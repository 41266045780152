import { GET_CONTACT_PAGE_REQUEST, GET_CONTACT_PAGE_SUCCESS, GET_CONTACT_PAGE_ERROR } from 'redux/constants';

export function getContactPageRequest() {
    return {
        type: GET_CONTACT_PAGE_REQUEST,
    }
}

export function getContactPageSuccess(data) {
    return {
        type: GET_CONTACT_PAGE_SUCCESS,
        payload: data,
    }
}

export function getContactPageError(error) {
    return {
        type: GET_CONTACT_PAGE_ERROR,
        payload: error,
    }
}
import { RTL_LANGUAGES } from 'constants/settings';

export const isRtl = (ln: string): boolean => RTL_LANGUAGES.indexOf(ln) !== -1;

export const matchType = (type: string): string[] | null =>
  /@(.*)\/(.*)(?:_(REQUEST|SUCCESS|ERROR))/.exec(type);

export const isLoading = (state) => state.api.loading.length > 0;

export const formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');

  //Check if the input is of correct
  let match = cleaned.match(/^(\d{0,2})?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    let intlCode = match[1] ? `+${match[1]} ` : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return null;
};

export const sortBy = (options) =>
  options.sort((a, b) => {
    if (Number(a.value.replace(' ', '')) > Number(b.value.replace(' ', '')))
      return 1;
    else if (
      Number(a.value.replace(' ', '')) < Number(b.value.replace(' ', ''))
    )
      return -1;
    else return 0;
  });

export const scaleContent = (wrapper) => {
  if (window.innerWidth > 1920 && wrapper.current) {
    scale();
    /*window.addEventListener('resize', scale);
            window.addEventListener("load", scale);*/
    function scale() {
      let wrapperWidth = wrapper.current.offsetWidth;
      let windowWidth = window.innerWidth;
      let r = 1;
      r = windowWidth / wrapperWidth;

      wrapper.current.style.transform = `scale(${r}) `;
      wrapper.current.style.transformOrigin = `top`;
      /*setTimeout(() => {
                    wrapper.current.parentElement.style.height = `${wrapper.current.getBoundingClientRect().height}px`;
                }, 400)
*/

      /*let header = document.getElementsByTagName('header');
                if (typeof(header) != 'undefined' && header != null)
                {
                    alert('exist');
                    /!*header.style.position = "absolute";*!/
                }*/

      let all = document.getElementsByTagName('*');
      for (let i = 0, max = all.length; i < max; i++) {
        if (all[i].offsetHeight === window.innerHeight) {
          all[i].style.height = `${100 / r}vh`;
          all[i].style.minHeight = `${100 / r}vh`;
        }
      }

      function scaleSlider() {
        let slidesContainer = document.querySelector('#main-slider');
        if (slidesContainer != null) {
          let slides = slidesContainer.getElementsByClassName('slider-image');
          for (let i = 0, count = slides.length; i < count; i++) {
            slides[i].style.height = `${100 / r}vh`;
          }
        }
      }

      scaleSlider();

      setTimeout(() => {
        scaleSlider();
      }, 900);
    }
  }
};

export const getFileExtension = (path) => {
  return path.split('.').pop();
};

export const getCenterFromDegrees = (data) => {
  if (!(data.length > 0)) {
    return false;
  }

  const numCoords = data.length;

  let X = 0.0;
  let Y = 0.0;
  let Z = 0.0;

  for (let i = 0; i < data.length; i++) {
    const lat = (data[i][0] * Math.PI) / 180;
    const lon = (data[i][1] * Math.PI) / 180;

    const a = Math.cos(lat) * Math.cos(lon);
    const b = Math.cos(lat) * Math.sin(lon);
    const c = Math.sin(lat);

    X += a;
    Y += b;
    Z += c;
  }

  X /= numCoords;
  Y /= numCoords;
  Z /= numCoords;

  const lon = Math.atan2(Y, X);
  const hyp = Math.sqrt(X * X + Y * Y);
  const lat = Math.atan2(Z, hyp);

  const newX = (lat * 180) / Math.PI;
  const newY = (lon * 180) / Math.PI;

  return [newX, newY];
};

import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import img from 'assets/svg/checked.svg';

const ThankYouWrapper = styled('div')`
  background: linear-gradient(180deg, #213a2e -11.25%, #1b493f 141.25%);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  font-family: "Din Pro";

  h2.title {
    font-weight: 400;
    color: white;
    margin-bottom: 0;
  }

  p {
    font-weight: 100;
    color: white;

    strong {
      font-weight: 400;
      font-style: italic;
    }
  }

  .MuiGrid-container {
    height: 100%;
  }
`;

const ThankYou = () => {
	const { success } = useSelector((state) => state.contactUs);

	const renderComponent = () => {
		return success ? (
			<ThankYouWrapper>
				<Grid container direction="column" justify="center" alignItems="center">
					<Grid item style={{ textAlign: 'center' }}>
						<img src={img} alt="checked" style={{ margin: '0 auto' }} />
						<h2 className="title">Thank you!</h2>
						<p>
							We will contact you within <strong>24 hours</strong>.
						</p>
					</Grid>
				</Grid>
			</ThankYouWrapper>
		) : null;
	};

	return renderComponent();
};

export default ThankYou;

import {
  GET_HELP_INFO_ERROR,
  GET_HELP_INFO_SUCCESS,
  GET_HELP_INFO_SEARCH_RESULTS_SUCCESS,
} from "redux/constants";

const initialState = {
  data: {
    blocks_titles: {},
    question_categories: {},
  },
  errors: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_HELP_INFO_SEARCH_RESULTS_SUCCESS:
      return { ...state, data: {...state.data, ...payload }, errors: null };
    case GET_HELP_INFO_SUCCESS:
      return { ...state, data: payload, errors: null };
    case GET_HELP_INFO_ERROR:
      return { ...state, errors: payload, data: [] };

    default:
      return state;
  }
}

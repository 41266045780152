import {
    GET_SERVICES_LIST_INFO_ERROR,
    GET_SERVICES_LIST_INFO_REQUEST,
    GET_SERVICES_LIST_INFO_SUCCESS,
} from "redux/constants";

export function getServicesListInfo() {
    return {
        type: GET_SERVICES_LIST_INFO_REQUEST,
    };
}

export function getServicesListInfoSuccess(data) {
    return {
        type: GET_SERVICES_LIST_INFO_SUCCESS,
        payload: data,
    };
}

export function getServicesListInfoError(err) {
    return {
        type: GET_SERVICES_LIST_INFO_ERROR,
        payload: err,
    };
}